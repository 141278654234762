@import '../../Flowingly.Shared.Angular/src/common.scss';

.process-map-v2-email-content-text {
  border: 1px solid $color-darkgrey;
  padding: 0px 10px;
  margin: 13px 0px;
  overflow: hidden;
}

.process-map-v2-email-content-wrapper {
  display: flex;
  gap: 5px;
}
