@import '../../../app.scss';
div .k-tabstrip-items {
  flex-direction: unset;
}

div .k-panelbar .k-tabstrip-items .k-state-active,
div .k-tabstrip-items .k-state-active {
  border-style: solid;
  border-bottom-color: #00b0ff;
}

.categories-with-subcategories-outer-container {
  div .k-grid {
    font-family: 'Open Sans', sans-serif !important;
    border-width: 0px;
  }

  div .k-table-td {
    font-size: 13px;
    color: $color-text-primary;
    background-color: $default-background-color;
  }

  div .k-grid tr td,
  .k-pivot-layout .k-grid tr td {
    background-color: $default-background-color;
  }

  div .k-treelist-scrollable > .k-table,
  .k-treelist-scrollable .k-grid-header .k-table-row,
  .k-treelist-scrollable .k-grid-header .k-table-th {
    background-color: $default-background-color;
  }

  .k-column-title {
    color: $color-text-primary;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 13px;
  }
}

.categories-with-subcategories-container {
  max-height: calc(100vh - 275px);
  overflow: auto;
  margin-top: 1px;
  .k-picker-solid {
    background-color: white !important;
  }
  .k-dropdowntree {
    .k-button {
      background-color: white !important;
    }
  }
  .k-picker-solid {
    border-color: transparent !important;
  }
  .k-picker-solid:focus,
  .k-picker-solid.k-focus {
    border-color: transparent;
  }
}
.setup li:hover {
  text-decoration: none !important;
}
