//ensure login error messages are wide enough
.auth0-lock-form .auth0-lock-error-msg {
  width: 120px;
}

//Hide Auth0 lock powered by badge
.auth0-lock-badge-bottom {
  display: none !important;
}

@media screen and (max-width: 480px) {
  .auth0-lock.auth0-lock.auth0-lock-opened .auth0-lock-header {
    background-color: #ddd;
  }
}

// Set login background opaque so that user info on edit profile modal won't leak when timeout
.auth0-lock.auth0-lock.auth0-lock-opened .auth0-lock-overlay {
  opacity: 1 !important;
}
