.runner-report {
  overflow: hidden;
  position: relative;
  background-color: #fff;
  min-height: 40px;
  line-height: 40px;
  padding: 5px 20px;
  font-size: 14px;
  border-bottom: 1px solid #ddd;

  .text {
    padding-left: 25px;

    span {
      font-size: 14px;
      font-weight: bold;
    }

    span:nth-of-type(2) {
      font-weight: normal;
    }
  }
}

.runner-report-alldata .k-grid button,
.runner-report button {
  padding: 0px 15px;
  vertical-align: middle;
  line-height: 26px;
  font-size: 13px;
  height: 30px;
  margin-top: 5px;
  margin-right: -10px;
  font-weight: normal;
}
