div.v2-custom-tabs {
  & .MuiTabs-indicator {
    background-color: #00b0ff;
    transition: none;
  }

  & .Mui-selected {
    background-color: #eee;
  }

  & .MuiTab-textColorPrimary.Mui-selected {
    color: inherit;
  }

  min-height: 35px;
  height: 35px;
}
