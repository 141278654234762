@import './app.scss';
body {
  background: $default-background-color;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  min-height: 100vh;
}

.content-wrap {
  background-color: $default-background-color;
}

#main-screen {
  height: 100%;
  min-height: 100vh;
}

.menu-desktop-pinned-content {
  margin-left: $width-side-menu-default;
}

[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak {
  display: none !important;
}

.runner-contents-area {
  max-width: $max-width-contents;
}

.spinner {
  position: fixed;
  top: 30%;
  left: 50%;
  z-index: 99999;
}

.page-title {
  font-size: 28px;
  font-weight: 600;
}

.row-title {
  font-size: 15px;
  line-height: 20px;
  font-weight: 600 !important;
}

.modal-title {
  font-size: 20px;
  font-weight: 600;
}

.secondary-text {
  font-weight: 600;
  font-size: 12px;
  color: $color-flow-content-secondary;
  line-height: 16px;
}

.mt-80 {
  margin-top: 80px !important;
}

.mt-90 {
  margin-top: 90px;
}

.mt-100 {
  margin-top: 100px;
}

.ml-25 {
  margin-left: 25px !important;
}

.pt-80 {
  padding-top: 80px !important;
}

.navbar-top.navbar-under {
  height: 45px;
  line-height: 45px;
}

.yay-toggle.hamburger {
  height: 45px;
  line-height: 45px;
  padding-top: 15px;
}

// Stylesheets will be injected below during build (dont remove)

//injector:sass

//endinjector

.p-relative {
  position: relative;
}

/* CONTROL PAGE LAYOUT */
.content-wrap {
  padding-right: 0;
}

.content-wrap > [ui-view] {
  margin-top: -70px;
  margin-left: -20px; /* will be overriden by .menu-desktop-pinned-content*/
}
/* remove padding extra for mobile views */
.isMobile {
  .content-wrap [ui-view] {
    margin-top: -58px;
    margin-left: 10px;
  }
}

.alert.alert-white {
  background-color: #fff;
  border: #ddd 1px solid;

  span {
    color: #888;
  }

  i {
    color: grey;
  }
}

.dueList {
  .runner-flow-list .collapsible.popout > li:last-of-type {
    margin-bottom: 25px;
  }
}

//TODO move materialize overrides to separate SASS
#toast-container {
  left: inherit;

  > div {
    opacity: 1;
  }
}

.toast {
  float: none;
  flex-direction: row-reverse;
  height: auto;
  width: 350px !important;
  line-height: normal;
}

.toast-close-button {
  align-self: flex-start;
}

.toast-message {
  word-break: keep-all;
}

.toast-top-center {
  top: 0 !important;
  right: 0 !important;
}

#hiw-login-container {
  margin: auto;
  width: 100%;
  padding-top: 50px;
}

.login-form-container {
  background: #35384c;
  height: 100%;
  min-height: 100%;
  min-height: 100vh;
}

#login-form .error-msg {
  margin-top: 0.5rem;
  width: 100%;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background-color: darkred;
  color: white;
  padding: 0 10px;
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: ' ';
  clear: both;
  height: 0;
}

.clearfix {
  display: inline-block;
}
/* start commented backslash hack \*/
* html .clearfix {
  height: 1%;
}

.clearfix {
  display: block;
}

/* close commented backslash hack */

.lh-38 {
  line-height: 38px !important;
}

.ml-34 {
  margin-left: 34px !important;
}

.file-details-list {
  margin-bottom: 20px;
}

.file-details-list li {
  margin-left: 30px;
  margin-bottom: 5px;
  padding: 10px;
  background: #dedeef;
  min-width: 150px;
  color: blue;
  font-weight: bold;
}

.card .content {
  padding: 10px;
}

.timeline {
  margin: 0 15px 0 15px;
}

.timeline-block {
  padding-left: 10px;
  padding-right: 10px;
}

@media (min-width: 768px) {
  #hiw-login-container {
    min-width: 400px;
  }

  .file-details-list li {
    margin-top: 0px;
  }

  .card .content {
    padding: 20px;
  }

  .timeline-block {
    padding-left: 10px;
  }
}

@media (min-width: 360px) {
  #hiw-login-container {
    max-width: 300px !important;
  }
}

@media (min-width: 992px) {
  .timeline {
    margin: 0 15px 0 15px;
  }
}

@media (min-width: 1024px) {
  .file-details-list li {
    margin-top: 0px;
  }

  .card .content {
    padding: 20px;
  }

  .timeline-block {
    padding-left: 10px;
  }
}

@media screen and (max-width: 50em) {
  .content {
    padding: 0 10px;
    text-align: left;
  }
}

.file-details-list li span {
  color: #999;
  padding-left: 3px;
}

.file-details-list li i {
  cursor: pointer;
}

.navbar-top {
  .yay-toggle div {
    width: $width-toggle-menu-button;
  }

  .brand-logo {
    width: $width-brand-logo;
    height: $height-brand-logo;
    padding: 0;
    margin-top: 10px;

    img {
      width: $width-brand-logo;
    }
  }
}

.active-flows {
  .collection.with-header .collection-item {
    border-bottom: 1px solid #ddd;
  }

  .collapsible-body-box {
    box-sizing: border-box;
    margin: 0 20px;
    padding: 20px 0;
  }
}

.cursor-default {
  cursor: default !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

span.badge {
  position: relative;
  right: auto;
  font-weight: 400;
  font-size: 0.9rem;
  color: #fff;
  background-color: $color-primary-blue;
  height: auto;
  line-height: 1;
  border-radius: 10px;
  min-width: 10px;
  padding: 3px 7px;
}

/*Overriding timeline to always show on the left hand side*/
.timeline:before {
  left: 28px;
  margin-left: 0;
}

.timeline-block {
  margin: 2em 0;
}

.timeline-block:first-child {
  margin-top: 0;
}

.timeline-block:last-child {
  margin-bottom: 0;
}

.timeline-icon {
  left: 0;
  margin-left: 0;
}

.timeline-content {
  width: 100%;
}

.timeline-content .timeline-date {
  position: relative;
  left: 0;
}

.timeline-block .timeline-content {
  float: none;
}

.timeline-block .timeline-content:before {
  left: auto;
  right: 100%;
  border-color: transparent;
  border-right-color: #fff;
}

.timeline-block .timeline-content .timeline-date {
  left: 0;
  text-align: left;
}

@media only screen and (max-width: 992px) {
  .timeline-icon {
    display: none;
  }

  .timeline-block {
    margin: 2em 0;
    padding-left: 10px;
  }

  .timeline:before {
    display: none;
  }

  .timeline-content.card-panel:before,
  .timeline-content.card:before {
    display: none;
  }
}
/*End of Overriding timeline to always show on the left hand side*/

.thumbnail {
  max-width: 150px;
}

.wrapper {
  margin: 2em;
}

/* override materilizeJs styles*/
.runner {
  .file-field .file-path-wrapper {
    position: absolute;
    left: 150px;
    top: 10px;
  }
}

.overdue-icon {
  width: 1.2rem !important;
  font-size: inherit !important;
  line-height: 1 !important;
  display: inline-block !important;
  float: none !important;
}

.collapsible-header .fa-paperclip {
  position: absolute;
  right: -28px;
  top: -50px;
}

.collapsible-header.mobile {
  padding: 0;

  .fa-paperclip {
    position: static;
    right: unset;
    top: unset;
  }
}

.list-options-header {
  margin-left: 40px;
}

.due-header {
  color: #aaa;
  margin-left: 60px;
  margin-top: 30px;
}

.due-header:first-of-type {
  margin-top: 0px;
}

@media (max-width: 600px) {
  .collapsible.popout > li.active {
    margin: 10px;
  }

  .timeline {
    margin: 0 15px 0 15px;
  }

  .list-options-header {
    margin: 0 auto;
    width: 100%;
    margin-top: 35px;
    text-align: center;
  }

  .due-header {
    color: #aaa;
    margin-left: 20px;
    margin-top: 20px;
  }

  .due-header:first-of-type {
    margin-top: 30px;
  }
}

.runner-page-header {
  margin-top: 7.5px;
  width: auto;
  font-size: 28px;
  white-space: nowrap;
}

.isMobile {
  .runner-page-header {
    display: inline-block;
    vertical-align: top;
  }
}

@media screen and (max-width: 767px) and (min-width: 320px) {
  .runner-page-header {
    margin-left: 15%;
  }
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .runner-page-header {
    margin-left: 35%;
  }
}

//iPad - both orientations
@media screen and (min-width: 620px) and (max-width: 1024px) and (min-height: 620px) and (max-height: 1024px) {
  .brand-logo.hide-on-med-and-down {
    display: none !important;
  }
}

//iPadPro - portrait
@media screen and (width: 1024px) and (height: 1366px) {
  .brand-logo.hide-on-med-and-down {
    display: none !important;
  }
}

//iPadPro - landscape
/*@media screen and (width: 1366px) and (height: 1024px) {
    .runner-page-header {
        margin-left: 12% !important;
    }
}*/

.username-container {
  max-width: 20vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  float: left;
}

.blue-header {
  border-bottom: 1px solid $color-primary-blue;
}

.user-avatar-image {
  background-size: cover;
  background-position: center center;
}

input[type='checkbox']:disabled + label,
input[type='radio']:disabled + label {
  cursor: default;
}

.h2,
h2 {
  font-size: 2.5rem;
  line-height: 1rem;
  margin: 0;
  margin-top: 35px;
}

.input-field label {
  font-weight: 600;
}

input[type='text'],
.k-dropdown-wrap.k-state-default {
  background-color: white !important;
}

.k-grid .k-input,
.k-combobox .k-select,
.k-dropdown .k-input {
  background-color: $color-white !important;
  border: none !important;
}

.k-tabstrip {
  .k-tabstrip-items {
    .k-link {
      padding: 7px 0.8em;
      line-height: 20px;
      font-size: 15px;
    }
  }
}

.pl-60 {
  padding-left: 60px !important;
}

.lookup-field:after {
  content: ' (lookup)';
  color: #97a8b1;
  font-weight: normal;
}

.error-msg {
  color: red !important;
}

.fa-pos-rel {
  position: relative;
}

.color-warning {
  color: $color-text-warning;
}

.k-filtercell {
  .k-widget.k-combobox.k-header {
    min-width: 120px;
  }
}

.k-maskedtextbox {
  display: inline;
}

.k-calendar .k-today .k-link {
  background-color: #32408f;
}

.k-calendar td.k-state-selected .k-link {
  background-color: $color-highlight-blue;
}

.k-treeview-leaf {
  flex-grow: 1;
  cursor: pointer;
}

#processmap-view {
  .italic-and-grayed {
    font-style: italic;
    color: $placeholder-color !important;
  }
}

.k-svg-icon > svg {
  height: 18px;
  width: 18px;
}

.k-animation-container {
  .k-drag-clue.k-state-selected,
  .k-draghandle.k-state-selected:hover,
  .k-ghost-splitbar-horizontal,
  .k-ghost-splitbar-vertical,
  .k-list > .k-state-highlight,
  .k-list > .k-state-selected,
  .k-marquee-color,
  .k-panel > .k-state-selected,
  .k-scheduler .k-scheduler-toolbar .k-state-selected,
  .k-scheduler .k-today.k-state-selected,
  .k-state-selected,
  .k-state-selected:link,
  .k-state-selected:visited {
    background-color: #ebebeb !important;
    border-color: #fff !important;
  }
}
