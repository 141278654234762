.delete-modal-legend {
  color: #35384d;
  padding: 3px 6px;
}

.delete-dialog {
  position: absolute;
  top: -27%;
  min-width: 500px;
  min-height: 300px;
  padding: 25px;
}

.delete-modal-fieldset {
  border: 1px solid silver;
}

.delete-modal-warning-icon {
  font-size: 13px !important;
  height: 13px !important;
  color: orangered;
}

.delete-modal-textfield input[type='text'] {
  margin: 0;
}