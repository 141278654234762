@import '../../Flowingly.Shared.Angular/src/common.scss';
/*
    We try to follow BEM as css standard, refer to http://getbem.com/ for more information.
    Legacy code may not follow this standard at all, however we are aiming for new codes.
*/

// from common.scss
$block-height: 40px;
$block-height-expanded: 100px;
$block-line-height-expanded: 50px;
$block-height-mobile: 100px;
$block-text-height-mobile: 22px;
$block-border-color: #ddd;
$block-header-avatar-width: 30px;
$block-header-avatar-width-expanded: 40px;
$block-header-padding-left-right: 10px;
$block-header-indent: 70px;
$block-header-indent-mobile: 35px;
$block-content-indent: 40px;
$history-start-height: 55px;
$history-block-height: 90px;
$history-background: #fafafa;
$history-content-secondary-color: #999;
$blue: #2196f3;
$green: #417505;
$pink: #ef9a9a;
$grey: #999999;
$history-black: #555;
$font-size-large: 16px;
$font-size-normal-mobile: 13px;
$font-size-normal: 13px;
$font-size-history-form-mobile: 12px;
$avatar-left-mobile: 15px;
$flow-box-shadom: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
  0 2px 10px 0 rgba(0, 0, 0, 0.12);
$progressbar-radius: 10px;
$progressbar-height: 8px;
