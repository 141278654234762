.category-navigation-mob-view {
  margin-bottom: 4px;
  .k-picker-md .k-input-button {
    width: auto;
  }
}

.category-navigation-mob-view {
  --kendo-primary-100: none;

  .k-picker:focus::after,
  .k-picker.k-focus::after,
  .k-picker:focus-within::after {
    border-color: transparent;
  }
}
.category-navigation-mob-view-dropdown-popup .k-treeview-leaf.k-focus {
  box-shadow: none !important;
}
