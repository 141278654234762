@import '../../app.scss';
.category-navigation-outer-container {
  .k-treeview {
    padding: 0;
    border-width: 0;
    background: none;
    box-sizing: border-box;
    outline: 0;
    font-family: inherit;
    font-size: 14px;
    line-height: 1.4285714286;
    display: block;
    cursor: default;
    overflow: auto;
    white-space: nowrap;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  .k-treeview *,
  .k-treeview *::before,
  .k-treeview *::after {
    box-sizing: border-box;
  }

  .k-treeview-filter {
    padding: 8px;
    display: block;
    position: relative;
    flex: none;
  }

  .k-treeview-group {
    margin: 0;
    padding: 0;
    list-style: none;
    position: relative;
    outline: 0;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  .k-treeview-group.ng-animating {
    overflow: hidden;
  }

  .k-treeview-top,
  .k-treeview-mid,
  .k-treeview-bot {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
  }

  .k-treeview-item {
    outline-style: none;
    margin: 0;
    padding: 0 0 0 24px;
    border-width: 0;
    display: block;
  }

  .k-treeview-toggle {
    margin-inline-start: -24px;
    flex: none;
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: center;
    cursor: pointer;
  }

  .k-treeview-toggle .k-icon {
    padding: 4px;
    box-sizing: content-box;
  }

  .k-treeview-loading {
    margin-right: 4px;
  }

  .k-treeview .k-checkbox-wrap,
  .k-treeview .k-checkbox-wrapper {
    align-self: center;
  }

  .k-treeview-leaf {
    border-radius: 4px;
    padding-block: 4px;
    padding-inline: 8px;
    border: 0px solid transparent;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    align-content: center;
    vertical-align: middle;
    position: relative;
  }

  .k-treeview-leaf .k-icon,
  .k-treeview-leaf .k-image,
  .k-treeview-leaf .k-sprite {
    margin-right: 4px;
  }

  .k-treeview-leaf.k-focus {
    z-index: 1;
  }

  .k-treeview .k-treeview-load-more-button {
    cursor: pointer;
  }

  .k-treeview .k-treeview-load-more-button:hover,
  .k-treeview .k-treeview-load-more-button.k-hover,
  .k-treeview .k-treeview-load-more-button:focus,
  .k-treeview .k-treeview-load-more-button.k-focus {
    text-decoration: underline;
  }

  .k-rtl .k-treeview .k-treeview-item,
  [dir='rtl'] .k-treeview .k-treeview-item,
  .k-treeview.k-rtl .k-treeview-item,
  .k-treeview[dir='rtl'] .k-treeview-item {
    padding-left: 0;
    padding-right: 24px;
  }

  .k-rtl .k-treeview .k-treeview-loading,
  [dir='rtl'] .k-treeview .k-treeview-loading,
  .k-treeview.k-rtl .k-treeview-loading,
  .k-treeview[dir='rtl'] .k-treeview-loading {
    margin-right: 0;
    margin-left: 4px;
  }

  .k-rtl .k-treeview .k-treeview-leaf .k-icon,
  .k-rtl .k-treeview .k-treeview-leaf .k-image,
  .k-rtl .k-treeview .k-treeview-leaf .k-sprite,
  .k-rtl .k-treeview .k-in .k-icon,
  .k-rtl .k-treeview .k-in .k-image,
  .k-rtl .k-treeview .k-in .k-sprite,
  [dir='rtl'] .k-treeview .k-treeview-leaf .k-icon,
  [dir='rtl'] .k-treeview .k-treeview-leaf .k-image,
  [dir='rtl'] .k-treeview .k-treeview-leaf .k-sprite,
  [dir='rtl'] .k-treeview .k-in .k-icon,
  [dir='rtl'] .k-treeview .k-in .k-image,
  [dir='rtl'] .k-treeview .k-in .k-sprite,
  .k-treeview.k-rtl .k-treeview-leaf .k-icon,
  .k-treeview.k-rtl .k-treeview-leaf .k-image,
  .k-treeview.k-rtl .k-treeview-leaf .k-sprite,
  .k-treeview.k-rtl .k-in .k-icon,
  .k-treeview.k-rtl .k-in .k-image,
  .k-treeview.k-rtl .k-in .k-sprite,
  .k-treeview[dir='rtl'] .k-treeview-leaf .k-icon,
  .k-treeview[dir='rtl'] .k-treeview-leaf .k-image,
  .k-treeview[dir='rtl'] .k-treeview-leaf .k-sprite,
  .k-treeview[dir='rtl'] .k-in .k-icon,
  .k-treeview[dir='rtl'] .k-in .k-image,
  .k-treeview[dir='rtl'] .k-in .k-sprite {
    margin-right: 0;
    margin-left: 4px;
  }

  .k-treeview-sm {
    font-size: 14px;
    line-height: 1.4285714286;
  }

  .k-treeview-sm .k-treeview-leaf {
    padding-block: 2px;
    padding-inline: 8px;
  }

  .k-treeview-sm .k-checkbox-wrap {
    padding-block: 2px;
    padding-inline: 4px;
  }

  .k-treeview-md {
    font-size: 14px;
    line-height: 1.4285714286;
  }

  .k-treeview-md .k-treeview-leaf {
    padding-block: 4px;
    padding-inline: 8px;
  }

  .k-treeview-md .k-checkbox-wrap {
    padding-block: 2px;
    padding-inline: 4px;
  }

  .k-treeview-lg {
    font-size: 16px;
    line-height: 1.5;
  }

  .k-treeview-lg .k-treeview-leaf {
    padding-block: 8px;
    padding-inline: 8px;
  }

  .k-treeview-lg .k-checkbox-wrap {
    padding-inline: 4px;
  }

  .k-treeview {
    color: #424242;
  }

  .k-treeview-leaf:hover,
  .k-treeview-leaf.k-hover {
    color: #424242;
    background-color: #ececec;
  }

  .k-treeview-leaf:focus,
  .k-treeview-leaf.k-focus {
    box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.13);
  }

  .k-treeview-leaf.k-selected {
    color: #ffffff;
    background-color: #1274ac;
  }

  .k-treeview .k-treeview-load-more-button {
    color: #1274ac;
    background-color: transparent;
  }

  .k-treeview .k-treeview-load-more-button:hover,
  .k-treeview .k-treeview-load-more-button.k-hover {
    color: #0f6190;
    background-color: transparent;
  }

  .k-treeview .k-treeview-load-more-button:focus,
  .k-treeview .k-treeview-load-more-button.k-focus {
    color: #0f6190;
    background-color: transparent;
    box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.13);
  }

  .k-popup-dropdowntree .k-treeview,
  .k-dropdowntree-popup .k-treeview,
  .k-multiselecttree-popup .k-treeview {
    padding-block: 4px;
    padding-inline: 4px;
  }

  .k-popup-dropdowntree .k-check-all,
  .k-dropdowntree-popup .k-check-all,
  .k-multiselecttree-popup .k-check-all {
    padding-block: 8px;
    padding-inline: 8px;
  }

  max-height: calc(100vh - 220px);
  overflow: auto;
}

.category-navigation-container {
  width: fit-content;
  td.k-table-td.k-text-nowrap {
    border: none !important;
  }

  .k-grid .k-table-th {
    border: none !important;
  }

  .k-treeview-item {
    padding: 5px 0px 5px 15px;
  }

  .k-treeview-md .k-treeview-leaf {
    padding-block: 2px;
    padding-inline: 8px;
    cursor: pointer;
  }

  .k-treeview-md {
    font-size: 13px;
  }

  .k-treeview-leaf.k-selected {
    background-color: inherit !important;
    color: $color-text-primary !important;
    font-weight: 700;
    position: relative;

    :before {
      background-color: $flow-icon-square-bg-color;
      content: '';
      height: 24px;
      left: 0;
      position: absolute;
      width: 5px;
    }
  }

  .k-treeview-leaf.k-focus {
    box-shadow: none !important;
  }

  .category-count {
    border-radius: 10px;
    margin-left: 10px;
    padding: 0 5px;
    margin-left: 5px;
    display: inline-block;
  }
  .category-name-ellipsis {
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .category-name-count-container {
    display: flex;
    align-items: center;
    max-width: 180px;
  }
}

[role='tooltip'] {
  visibility: visible;
}

.category-navigation-mob-view {
  background-color: rgba(255, 255, 255, 0.9);
  width: 100%;
  height: 3rem;
  border: 1px solid #f2f2f2;
  border-radius: 2px;

  .k-picker-solid {
    background-color: white !important;
    padding: 3px 0;
  }
  .k-button {
    background-color: white !important;
  }
  .k-input,
  .k-picker {
    border: none;
  }
  .k-input-value-text {
    color: #35384d !important;
  }
}
