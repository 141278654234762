.signup-nav {
  color: black;
  background: #2980b9;
  font-weight: bold;
}

.sign-up {
  .content {
    max-width: 800px;
    margin: auto;

    em,
    label {
      line-break: anywhere;
    }

    .validation-errors {
      margin-top: 10px;
      padding: 10px;
    }

    #signupType {
      margin-top: 82px;
    }

    .settings {
      max-height: 300px;
      overflow-y: auto;
      margin-bottom: 20px;

      .setting {
        padding: 5px 5px;

        &.odd {
          background-color: #f0f0f0;
        }
        .setting-name {
          width: 250px;
          flex-shrink: 0;
          font-weight: 600;
        }

        .setting-value {
          overflow-wrap: anywhere;
          padding: 3px 10px;
          width: 250px;
        }

        .setting-comments {
          width: 300px;
          overflow-wrap: anywhere;
          padding: 3px 10px;
          flex-grow: 1;
        }
      }
    }
  }
}
