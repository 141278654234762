@import '../../../../app.scss';
.setup-categories-name-filter {
  .k-button {
    background-color: $default-background-color !important;
    border-color: $default-background-color !important;
  }

  .k-input-value-text {
    font-size: 13px;
    font-family: 'Open Sans', sans-serif !important;
    color: #888 !important;
  }

  .name-filter {
    .k-input-value-text {
      overflow: visible;
    }
  }
}

.k-animation-container.setup-categories-name-filter-dropdown.k-animation-container-shown {
  .k-child-animation-container {
    .k-popup.k-dropdowntree-popup {
      max-height: 400px;
    }
  }
}

.setup-categories-name-filter-dropdown span.k-treeview-leaf.k-selected {
  color: $color-text-primary !important;
  background-color: #ebebeb !important;
}
