@import '../../app.scss';
@media only screen and (max-width: $max-width-contents) {
  .flow-history-step {
    padding-left: 60px;
  }
}

.flow-history-step {
  background-color: $history-background;
  border: 1px solid $block-border-color;
  border-top: none;
  min-height: $block-height;
  line-height: 20px;
  padding-top: 10px;
  padding-left: 70px;
  padding-bottom: 10px;
  position: relative;

  .history-field-signature img {
    max-width: 100%;
  }

  .history-field-signature p {
    font-weight: 400;
    font-size: 13px;
    font-style: italic;
    color: #97a8b1;
  }

  .avatar-container {
    top: 10px;
  }

  .avatar {
    top: 5px;
    left: 25px;
  }

  .text {
    margin-left: -10px;
  }

  .time-divider {
    padding: 0;
    line-height: 1rem;
    text-align: center;
    position: relative;
    display: block;
    margin-top: -20px;
    margin-left: -70px;
  }

  .time-divider-label {
    background: $default-background-color;
    padding: 0.25rem 0.75rem;
    display: inline-block;
    margin: 0 auto;
    position: relative;
    border-radius: 1rem;
  }

  .history-step-form {
    margin: 0 auto;
    padding-left: 0px;

    ul {
      float: left;
      display: inline-block;
    }

    .custom-email-content {
      ul {
        float: none;
        padding-left: 45px;
        margin-top: 0;

        li {
          list-style-type: disc;
        }
      }

      strong {
        font-weight: 600;
      }
    }

    .flow-table-viewer {
      width: 98%;
    }

    .step-header {
      display: block;
      margin-top: 20px;
      font-weight: 600;
      margin-bottom: 2px;
    }

    @media only screen and (max-width: 600px) {
      .step-header {
        margin-left: 0px !important;
      }

      .history-field-signature img {
        max-width: 90%;
      }
    }

    .step-value {
      display: block;
      margin-right: 5px;
      /*START text-area stylings*/
      strong {
        font-weight: bold;
      }

      ul,
      ol {
        margin-left: 20px;
        padding-left: 0;
        float: none;
        display: block;
      }

      ul li {
        list-style-type: disc;
      }

      ol li {
        list-style-type: decimal;
      }

      table {
        max-width: 100%;
      }
      /*END text-area stylings*/
      [type='radio']:disabled:checked + label:before,
      [type='radio']:disabled:not(:checked) + label:before,
      [type='radio'] + label:after {
        margin-left: 0;
      }
    }

    li {
      margin: 0;
      line-height: 22px;
    }

    table.bordered {
      border: 1px solid grey;
      border-collapse: collapse;

      th,
      td {
        border: 1px solid grey;
        border-collapse: collapse;
      }
    }

    [type='radio']:disabled + label {
      color: #35384d;
    }

    [type='radio']:disabled:checked + label:after {
      left: 2px;
      top: 2px;
    }

    [type='checkbox'].filled-in:disabled:checked + label:before {
      left: -2px;
      top: -2px;
    }

    .no-value-text {
      font-style: italic;
      color: $color-flow-content-secondary;
    }

    .custom-email-label {
      font-weight: 600;
      margin-right: 5px;
    }
  }
}

.history-step-form {
  font-size: $font-size-normal;
}

.history-step-form span:first-of-type {
  font-weight: normal;
}

.history-step-form.isMobile {
  ul {
    font-size: $font-size-history-form-mobile;
  }
  .text {
    margin-left: -20px;
  }
}

.flow-history-step {
  .isMobile .text {
    margin-left: -20px;
  }
}
.flow-history-step.isMobile {
  padding-left: 45px;
  padding-top: 5px;

  .history-step-form {
    padding-left: 20px;
    font-size: $font-size-history-form-mobile;
  }

  .time-divider {
    margin-top: -15px;
  }

  .text {
    line-height: 16px;
    padding-top: 10px;
    padding-bottom: 2px;
    margin-right: 5px;
  }

  .form-text {
    padding-top: 5px;
    padding-bottom: 2px;
    margin-right: 5px;

    span {
      width: 100%;
      display: block;
      line-height: $font-size-history-form-mobile + 2;
    }
  }

  .avatar {
    left: 40px;
    top: 10px;
  }
}

.field-container {
  width: 100%;
}

.file-upload-link-custom-email {
  color: $color-highlight-blue;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
}

.dl-not-supported {
  font-weight: 600;

  div {
    height: 15px;
    line-height: 25px;
    float: left;
  }
}

.confidential-step {
  padding-right: 2rem;
  float: right;
}
