.categories-row {
  background: transparent;
}

.cards-col {
  width: 85%;
  float: right !important;
}

.categories-col {
  width: 15%;
  min-width: 200px;
  height: 80vh;
  background-color: transparent;
  position: fixed;
  margin-left: 15px;
  .categories-list {
    background-color: transparent;
    border: none;
    height: 100%;
    overflow-y: auto;
    position: relative;
    overflow-x: hidden;
  }

  .categories-list-item {
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    padding-left: 15px;
    align-items: center;

    .category-name {
      overflow-x: clip;
    }
    .category-count {
      margin-left: 10px;
      border-radius: 10px;
      padding: 0px 5px;
    }
  }
  .selected-category {
    font-weight: bold;
    position: relative;
  }

  .selected-category::before {
    content: '';
    width: 5px;
    height: 24px;
    background-color: #378687;
    position: absolute;
    left: 0px;
  }
}
