@import '../app.scss';
.public-form {
  padding: 20px;
  height: 100%;
  min-height: 100vh;

  .loading {
    font-size: 0;
    width: 30px !important;
    height: 30px;
    margin-top: 150px;
    margin-bottom: 150px;
    margin-left: calc(50% - 15px) !important;
    border-radius: 15px;
    padding: 0 !important;
    border: 3px solid $color-action-green;
    border-bottom: 3px solid rgba(255, 255, 255, 0);
    border-left: 3px solid rgba(255, 255, 255, 0);
    background-color: transparent !important;
    animation-name: rotateAnimation;
    -webkit-animation-name: wk-rotateAnimation;
    animation-duration: 1s;
    -webkit-animation-duration: 1s;
    animation-delay: 0.2s;
    -webkit-animation-delay: 0.2s;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
  }

  @keyframes rotateAnimation {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  @-webkit-keyframes wk-rotateAnimation {
    0% {
      -webkit-transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(360deg);
    }
  }
}

.public-form-content {
  min-height: 600px;
  margin: auto;
  background-color: white;
  padding: 10px 50px;
  border-radius: 10px;
  box-shadow: none;

  .row-title {
    position: relative;
  }

  h5 {
    font-weight: 600;
  }
}

.public-form-form {
  text-align: center;

  .form-field-wrapper {
    text-align: left;
  }
}

.public-form-footer {
  margin: 20px 0 10px 0;
}

.public-form-footer__text,
.public-form-footer__logo {
  display: inline-block;
}

.public-form-footer__text {
  vertical-align: top;
  margin-top: 5px;
  margin-right: 5px;
  color: #97a8b1;
}

.public-form--info-icon {
  font-size: 90px;
}

.public-form--info-icon--tick {
  color: $color-action-green;
}

.public-form--info-icon--error {
  color: black;
}
