@import '../../../../app.scss';

.governance-button-group-container {
  margin-left: 60px;
  margin-bottom: 10px;
  margin-top: -15px;
  .governance-button-group {
    text-transform: none;
    background: none;
    border: 1px solid transparent;
    border-radius: 0%;
    color: $color-text-primary;
    font-family: 'Open Sans', sans-serif;
    font-size: 15px !important;
    letter-spacing: 0;
    &.selected {
      border-bottom-color: $color-primary-blue;
      border-width: 2px;
      background-color: #eeeeee !important;
    }
    &:hover {
      background: none;
      border-color: transparent;
      border-bottom-color: $color-tab-border-hover;
      border-bottom-width: 2px;
      border-right-color: transparent !important;
      transition: none !important;
    }
  }
}

.governance-list-contents {
  width: 100%;
}

.governance-list-item {
  position: relative;
  margin-left: 2%;
  margin-right: 2%;
  width: 34%;
}

.governance-list-item-text {
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  margin: 15px 0 5px 0;
  text-align: left;
  word-break: break-word;
}

.governance-list-item-sub-text {
  font-weight: 600;
  font-size: 12px;
  color: $color-flow-content-secondary;
  line-height: 16px;
  margin-bottom: 15px;
  word-wrap: break-word;
}
