@import '../../app.scss';
.flow-history-waiting {
  background-color: white;
  border: 1px solid $block-border-color;
  padding-left: 70px;
  padding-top: calc($history-block-height / 7) + 1;
  padding-bottom: calc($history-block-height / 7) + 1;
  cursor: pointer;

  .current-step-display-moment,
  .current-step-display-moment-due {
    margin-top: 3px;
  }

  .avatar {
    top: 7px;
    left: 30px;
  }

  .time-divider {
    padding: 0;
    line-height: 1rem;
    text-align: center;
    position: relative;
    display: block;
    margin-top: -20px;
    margin-left: -70px;
    /*&:after {
            content: '';
            position: absolute;
            width: 98%;
            left: 1%;
            margin-top: 10px;
            border-bottom: 1px solid #ddd;
        }*/
  }

  .text {
    margin-left: -10px;
  }

  &:hover {
    background-color: #e5f2fc;
    transition: all 200ms ease-out;
  }

  .step-processing-avatar {
    top: 4px;

    .step-processing-icon {
      font-size: 24px;
      color: #97a8b1;
    }
  }
}

.flow-history-waiting--selected {
  background-color: #e5f2fc;
}

.flow-history-waiting__contents {
  margin-top: 10px;
}

.current-step-display-moment {
  margin-top: 10px;
  width: 75%;
}

current-step-display-moment-due {
  margin-top: 10px;
  width: 20%;
}

.flow-history-waiting.isMobile {
  padding-left: 45px;
  padding-top: 5px; //0px when an avatar / else 5px
  .text {
    line-height: 16px;
    padding-top: 5px;
    padding-bottom: 2px;
    margin-top: 0;
    margin-right: 5px;
    margin-left: 5px;
  }

  .avatar {
    left: 45px;
    top: 5px;
  }
}
/* alternate styles if this component is displayed in the footer */
.flow-form-footer {
  .flow-history-waiting {
    display: inline-block;
    min-height: $history-block-height;
    border-bottom: none;
    padding-left: $block-header-indent;
    padding-top: 0;

    .avatar {
      top: 5px;
      left: -70px;
    }

    .time-divider {
      display: none;
    }
  }

  .flow-history-waiting.isMobile {
    padding-left: 45px;
    padding-top: 5px; //0px when an avatar / else 5px
    .text {
      line-height: 16px;
      padding-top: 10px;
      padding-bottom: 2px;
      margin-right: 5px;
      margin-left: 15px;
    }

    .avatar-container {
      left: -90px;
    }

    .avatar {
      left: 40px;
      top: 0px;
    }
  }
}
