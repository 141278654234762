@import 'app.scss';

.v2-view-breadcrumb {
  &-text-wrapper {
    display: block;
    cursor: pointer;
  }

  &-heading-bold-text {
    font-weight: bold;
  }

  &-element-container {
    display: inline-flex;
    min-width: fit-content;

    &-inner {
      height: 50px;

      &-item {
        display: flex;
        align-items: center;
        height: 100%;
        flex: 0 15%;
        position: relative;

        &.active {
          border-bottom: 3px solid $color-highlight-blue;

          .v2-view-breadcrumb-element-container-inner-link,
          .v2-view-breadcrumb-element-container-inner-heading {
            color: $color-highlight-blue;
          }
        }

        p {
          display: flex;
          flex-direction: column;
        }
      }

      &-arrow {
        border: solid $grey;
        display: inline-block;
        padding: 14px;
        border-width: 0 3px 3px 0;
        transform: translateY(22.5px) rotate(-45deg) skew(-12deg, -11deg)
          translateX(27px);
        z-index: 1;
      }

      &-icon {
        margin: 5px;
        position: absolute;
        color: $grey;
        font-size: 18px;
        right: 0;
        z-index: 2;
      }

      &-heading,
      &-link {
        text-decoration: none;
        font-size: 13px;
        flex: 1;
        padding-left: 30px;
        text-align: center;
        display: block !important;
        margin: 0;
        width: 15rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}
