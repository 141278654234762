@import '../../../../../app.scss';

.copy-tooltip-wrapper {
  position: relative;

  &:hover {
    cursor: pointer;
  }

  &[aria-disabled='true'] {
    cursor: not-allowed;
  }
}

.copy-tooltip-wrapper .copy-tooltip-icon {
  font-size: 18px;
}

.copy-tooltip {
  font-size: 13px;
  font-family: sans-serif;
  background-color: $history-black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  margin: 0px;

  position: absolute;
  left: -100%;
  bottom: 140%;
  transition: opacity 0.5s ease-in-out;

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 40%;
    border-width: 5px;
    border-style: solid;
    border-color: $history-black transparent transparent transparent;
  }

  &.fade-in {
    opacity: 1;
  }

  &.fade-out {
    opacity: 0;
    visibility: hidden;
  }
}
