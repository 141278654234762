.runner {
  .not-clickable {
    pointer-events: auto;
  }

  .hide-runner {
    display: none;
  }
}
/* START angular form gen styles */
.fg-tabs-pane {
  clear: both;
}

.fg-tabs .tab-content {
  border: none !important;
  padding-bottom: 20px;
}
/*.ngdialog.ngdialog-theme-plain {
    padding-top: 50px!important;
}*/
card {
  .container {
    width: 100%;
  }

  .modal-content {
    box-shadow: none;
    border: none;
  }

  fieldset {
    border: none;
  }

  .fix-to-bottom {
    position: absolute;
    bottom: 0;
  }
  /* materialize / con overrides */
  /* to align with adjacent buttons (add/validate)*/
  .alert {
    padding: 9px;
  }

  [type='checkbox']:not(:checked),
  [type='checkbox']:checked {
    position: relative;
    opacity: 1;
    visibility: visible;
    left: 0px;
  }

  [type='radio']:checked,
  [type='radio']:not(:checked) {
    position: relative;
    opacity: 1;
    visibility: visible;
    left: 0px;
  }

  .dropdown-content li > a,
  .dropdown-content li > span {
    color: #428bca;
  }
}

select.flowSelect {
  display: block !important;
}

.instruction-field {
  margin: 0;
  padding: 0;
  max-width: 100%;
}

/* END angular form gen styles */
