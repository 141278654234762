.report-header {
  font-size: 14px;
}

.report-group-list {
  margin-top: 20px;

  &.isMobile {
    margin-left: 0px;
  }
}
