.process-map-view-v2-left-panel-process-detail-container {
  width: 99vw;
  height: calc(100vh - 190px);
  padding-top: 0;
  padding-left: 0;
  padding-right: 70px;
  margin-top: -25px;
}

.process-map-view-v2-left-panel-process-detail-grid {
  width: 100%;
  padding: 20px;
  background-color: #fafafa;
}

.process-map-view-v2-left-panel-process-detail-time-cost-box {
  width: fit-content;
}

.process-map-view-v2-left-panel-process-detail-time-cost-row {
  display: flex;
  justify-content: space-between;
  padding-top: 1rem;
}

.process-map-view-v2-left-panel-process-detail-custom-divider {
  background-color: #a9a9a9;
  width: 100%;
}
