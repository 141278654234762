@import '../../app.scss';
.password-calculator-suggestions {
  margin-top: 3px;
  font-size: 12px;
  color: #bdbdbd;
}

.ngdialog {
  .row {
    padding: 0 5px;
    margin: 0;
  }

  .input-field.col {
    padding: 0 15px 0 0;
  }

  h5 {
    margin-left: -2px;
    margin-bottom: 10px;
  }

  label.input-field {
    font-size: 13px;
  }

  hr {
    margin: 10px 0 0 0;
  }

  .edit-user-modal {
    padding-right: 0 !important;

    .k-tabstrip-items .k-state-active,
    .k-tabstrip-items .k-state-default {
      background-color: white !important;
    }

    .k-tabstrip-items .k-state-default {
      width: 33%;
    }

    .four-tabs {
      .k-tabstrip-items .k-state-default {
        width: 25% !important;
      }
    }

    .k-panelbar .k-tabstrip > .k-content,
    .k-tabstrip > .k-content {
      padding: 0;
    }

    ul li {
      list-style-type: disc;
    }
  }
}

.edit-user-modal__invite-user {
  width: 100px !important;
}

//seems there is some issue with angular and as soon as any input is marked disabled, it is considered invalid and red validation line is shown.
//to overwrite style doing this
//https://github.com/angular/angular/issues/11432
.disabledInput {
  border-bottom: 1px solid $color-action-green !important;
  box-shadow: 0 1px 0 0 $color-action-green !important;
}

fieldset.delegation-warning-box {
  border: 1px solid #808080;
  margin: 30px 0 60px;
  padding-top: 20px;
  padding-bottom: 20px;

  .row {
    div + div {
      margin-top: 20px;
    }
  }

  legend {
    font-weight: bold;
    padding: 0 5px;
    font-size: 14px;
    margin-left: 20px;
  }

  .delegation-warning-text {
    padding-left: 20px;
  }
}

.delegation-warning-text .fa-light {
  position: absolute;
  left: 18px;
  line-height: 22px;
  color: $color-text-warning;
}

.useroverlay {
  z-index: 1000;
  border: none;
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background-color: rgb(255, 255, 255);
  opacity: 0.1;
  position: absolute;
}
.posrel {
  position: relative;
}

.delegation-validation-msg {
  color: #e53935;
}

users-header {
  display: flex;
  flex-wrap: nowrap;
}

.user-profile-disabled {
  pointer-events: none;
  opacity: 0.5;
}

.flow-model-approver-message {
  color: $grey;
  width: 40%;
  font-size: 11px;
  margin-left: 59%;
  margin-top: 11%;
}
