@import '../app.scss';
$color-text-cloud: #17a2e6;

.import-modal {
  padding: 10px;
}

.import-modal__importing-message {
  height: 358px;
  text-align: center;
  padding-top: 100px;

  .fa-spinner {
    font-size: 10em;
    color: $color-text-cloud;
  }
}

.import-modal__importing-message__text {
  color: $color-flow-content-secondary;
  font-size: 15px;
  margin-top: 5px;
  font-style: italic;
}

.import-modal__step {
  margin-bottom: 10px;
}

.import-modal__step-circle {
  border-radius: 50%;
  background-color: #42a5f5;
  height: 45px;
  width: 45px;
  display: inline-block;
  padding: 10px;
  margin-right: 5px;
  color: white;
  text-align: center;
  line-height: 15px;
}

.import-modal__step-description {
  padding-top: 13px;

  a {
    cursor: pointer;
  }
}

.import-modal__step-description--step-2 {
  padding-top: 8px;
  line-height: 15px;
}

.import-modal__file-to-import {
  text-align: center;
}

.import-modal__cloud-upload {
  font-size: 8em;
  color: $color-text-cloud;
  cursor: pointer;
}

.import-modal__delete {
  cursor: pointer;
  font-size: 1.2em;
}

.import-modal__file-to-import--dragover {
  border: 3px dashed $color-text-cloud;
}

.import-modal__error-message {
  color: $color-text-error;
}

.import-modal__error {
  table {
    thead {
      white-space: nowrap;

      th {
        padding-right: 20px;
      }
    }

    td:nth-of-type(1) {
      width: 175px;
    }

    td:nth-of-type(2) {
      width: 160px;
    }

    tr {
      border-bottom: 1px solid $color-border;
      word-break: break-word;
    }
  }
}

.flow-models-importer {
  .importable-flow-models {
    max-height: 70vh;
    overflow-y: auto;

    .importable-flow-model {
      display: flex;
      justify-content: space-between;
      gap: 10px;
      margin-bottom: 1.5em;

      .details {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        width: 80%;
        color: $color-grey;
        label {
          color: $color-text-primary;
        }
      }

      .name-clash {
        color: $color-burnt-orange;
        font-weight: 600;
      }
    }
  }

  .import-action {
    height: 50px;
    padding: 10px 5px 0px 0px;
  }

  .import-option {
    width: 20%;
    margin-top: 8px;
  }

  .import-result {
    width: 20%;
    text-align: center;
    margin-top: 1em;
    padding-top: 1em;
    height: 3.5em;
    border-radius: 2em;
    &.success {
      background-color: $color-forrest-lighter-20;
    }
    &.failure {
      background-color: $color-redish-lighter-20;
    }
  }

  #flow-model-importer-file-input {
    display: none;
  }

  #drop-zone {
    width: 100%;
    text-align: center;
    border: 5px solid $color-grey-lighter-50;
    background-color: $color-grey-lighter-80;
    border-radius: 1em;
    cursor: pointer;
    margin: 0em 0em 2em 0em;
    padding: 1em 0em;

    &:hover {
      border: 5px solid $color-legacy-blue-lighter-20;
      color: $color-legacy-blue;
      background-color: $color-legacy-blue-lighter-80;
    }
  }
}
