@import '../../app.scss';
.filter-cell-container {
  display: block;
  width: 120px;
  height: 80px;
  padding: 2px;
  margin-right: 7px;
  box-shadow: $flow-box-shadom;
  &.selected {
    background-color: aqua;
  }
  .filter-cell {
    display: block;
    width: 100%;
    height: 100%;
    background-color: white;
    cursor: pointer;

    .row1 {
      height: 40%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      font-size: 13px;
      .left-column {
        display: inline-block;
        margin-right: 2px;
      }
      .right-column {
        display: inline-block;
      }
    }

    .row2 {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 60%;
      font-weight: 700;
      font-size: 16px;
    }
  }
}
.k-tooltip {
  border-color: rgba(100, 100, 100, 0.9);
  background-color: rgba(100, 100, 100, 0.9);
  border-width: thin;
  box-shadow: none;
}

.k-tooltip-content {
  color: white;
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
}

.filter-cell-selection {
  border: 2px solid $color-primary-blue;
}