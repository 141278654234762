@import '../../../app.scss';

.governance-search-tabs-container {
  display: flex;
  align-items: center;
  margin-left: 5%;
  justify-content: flex-start;

  .hidden {
    opacity: 0;
    visibility: hidden;
  }

  .search-container {
    min-width: 400px;
    max-width: 1000px;
    padding-right: 2rem;
  }
}
