$flow-icon-square-bg-color: #378687;
$avatar-size: 35px;
$avatar-size-small: 20px;
.flow-icon-square {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60px;
  min-width: 60px;
  background-color: #378687;
  color: white;
  font-weight: 600;
  font-size: 17px;
}
$media-height: 67px;
