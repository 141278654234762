#feature-admin-view {
  .feature-admin-business {
    display: flex;

    .switch {
      flex-shrink: 0;
    }

    .feature-admin-business-name {
      margin-top: 5px;
      margin-left: 2em;
      overflow-x: hidden;
    }
  }
}
