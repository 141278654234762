@import '../../app.scss';
.flow-history-nudge {
  position: relative;
  border-bottom: 1px solid $block-border-color;
  background-color: $history-background;
  min-height: $history-block-height;
  line-height: $history-block-height;
  padding-left: $block-header-indent;
  padding-top: 5px;

  .avatar {
    top: 5px;
  }

  .text {
    margin-left: -10px;
  }
}

.nudge-form {
  hr {
    margin-top: 15px !important;
  }

  .who-is-nudged {
    font-weight: bold;
    font-style: italic;
  }
}

.flow-history-nudge__contents {
  margin-top: 10px;
}

/* MOBILE ///////////////////////////////////////////////////////////// */
.flow-history-nudge.isMobile {
  padding-top: 5px;

  .text {
    line-height: 16px;
    padding-bottom: 5px;
    margin-top: 5px;
    margin-right: 5px;
    margin-left: -20px;
  }
}
