$avatar-size: 40px;
$details-section-left-margin: 50px;

.flow-step-task-form-title {
  font-size: 16px;
}

.flow-step-task-details-container {
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-top: 10px;
  background-color: white;
  margin-left: 65px;

  .flow-step-task-details {
    margin-top: 10px;
    margin-left: $details-section-left-margin;

    p {
      margin-top: 0px;
      margin-right: 100px;
    }
  }
}

.flow-step-task-activity-container {
  padding-left: 0px;
  padding-right: 10px;
  padding-bottom: 0px;
  padding-top: 0px;
  background-color: white;
  margin-left: 45px;
  margin-top: 20px;

  .flow-step-task-activity-details {
    border: none;
    margin-left: $details-section-left-margin;

    .flow-step-task-activity-footer-buttons {
      margin-right: 90px;
      margin-top: -10px;
    }

    .flow-step-task-comment-container {
      margin-top: -10px;
      margin-right: 80px;

      .avatar-container {
        left: 0px !important;
      }

      .flow-comments-container {
        border: none !important;
        padding: 0px !important;
        margin-left: 20px;

        .flow-comments-container__inner {
          height: auto; // Makes the comments grow in height when adding.
          .flow-comments-list {
            max-height: none !important;
            overflow-y: auto !important;

            .flow-comment-history__comment-container {
              // Override to make comments smaller for step tasks.
              margin-top: 0px !important;
              min-height: 50px !important;
              margin-bottom: 0px !important;
              padding-top: 5px !important;
              padding-bottom: 5px !important;
            }
          }

          .flow-add-comment-container {
            margin-left: -20px !important;
            margin-top: -15px !important;
            padding-left: 20px !important;
          }
        }
      }
    }
  }
}

.flow-step-task-approval-container {
  padding-left: 0px;
  padding-right: 10px;
  padding-bottom: 0px;
  padding-top: 0px;
  background-color: white;
  margin-left: 70px;

  .flow-step-task-approval-details {
    border: none;
    margin-left: $details-section-left-margin;

    .flow-step-task-approval-comment-row {
      margin-right: 100px;
      margin-top: 10px;
    }

    .flow-step-task-approval-radio-button {
      padding: 0px;
      margin-top: 10px;
      margin-left: -5px;
      position: relative; // Set to relative to prevent the browser showing its scroll bar.
    }

    .flow-step-task-approval-footer-buttons {
      margin-right: 90px;
    }
  }

  .flow-step-task-approval-details-validation-msg {
    color: #e53935;
  }
}

.flow-step-task-message {
  height: $avatar-size;
  border: none;

  .avatar {
    top: 0 !important;
    left: 0 !important;

    p {
      line-height: $avatar-size;
      width: $avatar-size;
      height: $avatar-size;
    }
  }
}
