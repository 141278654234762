@import '../app.scss';

.search-container {
  display: flex;
  min-width: 20em;
  margin-top: 3px;
  margin-bottom: 1rem;
  white-space: nowrap;
  overflow: hidden;
  position: relative;

  .search-icon {
    margin-right: -32px;
    line-height: 40px !important;
    color: grey !important;
    margin-left: 4px;
    width: 25px;
    z-index: 1;
  }

  input {
    padding-left: 30px;
  }

  .search-clear {
    margin-left: -32px;
    padding: 12px 10px;
  }

  .fa-times {
    color: $color-text-primary;
    font-weight: bold;
    cursor: pointer;
  }

  .fa-times:hover {
    color: $blue;
  }
}
