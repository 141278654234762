@import './app.scss';
.flow-library {
}

.flow-library__grid {
  margin: 20px 25px 0 25px;

  td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

/* dialog styles */
.ngdialog-content .card .content {
  padding-top: 0;
  padding-left: 10px;
  margin-right: 10px;
}

.box-shadow {
  box-shadow: 0 -1px 0 0 rgba(0, 0, 0, 0.1);
}

.unpublish-message {
  margin: 0 0 15px 0;
  padding: 0 15px;
  border: 1px solid #efefef;
  border-radius: 3px;
  color: #888 !important;
}

.loading-message {
  min-height: 400px;
  padding: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  color: #888;
}

.unpublished-changes-message {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: normal;
}

.val-badge {
  min-width: 3rem;
  text-align: center;
  line-height: inherit;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-weight: 400;
  padding: 5px 7px;
  border-radius: 3px;
  opacity: 0.7;
}

.val-badge--draft {
  border: 2px solid #727272;
  color: #727272;
}

.val-badge--validated {
  border: 2px solid #f57c00;
  color: #f57c00;
}

.val-badge--published {
  border: 2px solid #0b7849;
  color: #0b7849;
}

.val-badge--published-public {
  border: 2px solid #00b0ff;
  color: #00b0ff;
}

.val-badge--published-sso {
  border: 2px solid  $color-redish;
  color:  $color-redish;
}

[role='tooltip'] {
  visibility: hidden;
}

.flow-library-list-actions {
  position: relative;
}

.flow-library-list-actions {
  .list-actions__action {
    margin-right: 9px;
  }

  .list-actions {
    float: right;
    margin-right: -10px;
  }
}

.flow-library-list-actions .list-action-disabled {
  i.fa-light {
    color: #b1b1b1 !important;
    z-index: 100;
    cursor: default;
  }
}

.list-actions-overlay {
  width: 120%;
  position: absolute;
  background-color: #ebebeb;
  left: 50%;
  top: 10px;
  transform: translate(-50%, -50%);
  z-index: 100;
  border: 2px solid #d4d4d4;
  border-radius: 5px;
}

.library-grid-container {
  margin-left: -10px;
  height: calc(100vh - 200px);
}

.library-tab {
  width: 100% !important;
  padding-left: 0px !important;
  padding-top: 0px !important;
  margin-top: -19px !important;
  background: $default-background-color !important;
}

.library-tab-list {
  background-color: $default-background-color !important;
  margin-left: 2rem;
  margin-bottom: 2.5rem;
}

.library-grid-container {
  .k-grid-content {
    position: relative;
    width: 100%;
    overflow: auto;
    overflow-x: auto;
    overflow-y: scroll;
    zoom: 1;
    min-height: 0;
    height: 57vh;
  }
}

.tab-width {
  width: 17rem !important;
}

.library-page-tab.k-widget.k-tabstrip {
  background-color: $default-background-color !important;
}
