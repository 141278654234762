@import '../../app.scss';
#maintenance-reports {
  #description {
    margin-top: 1em;
  }
  #report-inputs {
    .report-input {
      margin-top: 1em;
      display: flex;

      label {
        flex-basis: 50%;
        margin-top: 10px;
      }
      input {
        flex-basis: 50%;
      }
    }
  }

  table {
    td {
      padding: 10px 5px;
      border-bottom: 1px solid $color-grey-lighter-50;
    }
  }
}
