p.process-map-v2-view-left-panel-header-empty-string {
  font-family: 'Open Sans', sans-serif;
  color: #a9a9a9;
  font-size: 13px;
}

p.process-map-v2-view-left-panel-header-non-empty-string {
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
}

.process-map-v2-view-left-panel-header {
  display: flex;
  flex-direction: column;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;

  .process-map-v2-view-left-panel-header-content {
    display: flex;
    font-weight: 600;
    padding-bottom: 6px;

    .process-map-v2-view-left-panel-header-process-owner {
      flex: 0 0 30%;
    }

    .process-map-v2-view-left-panel-header-process-description {
      flex: 0 0 70%;
    }
  }

  .process-map-v2-view-left-panel-header-details-content {
    display: flex;

    .process-map-v2-view-left-panel-header-process-owner {
      flex: 0 0 30%;
    }

    .process-map-v2-view-left-panel-header-process-description {
      flex: 0 0 70%;
      width: 70%;
      .process-map-v2-view-left-panel-header-description-typography {
        color: #35384d;
        font-family: 'Open Sans', sans-serif;
        font-size: 13px;
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
      }
    }
  }
}
