@import '../../app.scss';
.flow-header {
  background-color: #fff;
  cursor: pointer;
  box-shadow: $flow-box-shadom;

  .fa-paperclip {
    margin-top: 10px;
    margin-right: 5px;
  }

  .flow-list__contents__workflow-type__block {
    width: 11%;
  }

  .flow-list__contents__workflow-type__header--mobile {
    margin-bottom: 0;
  }
}

.single-flow-view {
  .flow-header {
    cursor: auto;
  }
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.flow-history-container,
.flow-history-container-leftpane,
.flow-current-steps-container-rightpane {
  font-size: $font-size-normal;

  .avatar-container {
    left: -80px;
  }
}

.flow-history-nudge {
  .avatar-container {
    left: -50px;
  }
}

.flow-list__contents__subject {
  position: relative;
}

.flow-list__contents__subject__comment-bubble {
  color: $color-flow-content-secondary;
}

.flow-list__contents__subject__comment-bubble__text {
  position: relative;
  top: -10px;
  left: -5px;
  font-size: 0.6em;
  font-weight: 600;
}

/* MOBILE ////////////////////////////////////////////*/
.flow-header.isMobile {
  min-height: $block-height-mobile;
  line-height: 30px;
  font-size: $font-size-normal-mobile;

  ul {
    list-style: none;
  }

  .text-container {
    padding: 0 10px;
  }
}

//OTHER BREAKPOINTS ///////////////////////////////////////
@media (max-width: 1135px) {
  .flow-header {
    .flex-grow-2,
    .flex-grow-1-5 {
      flex-grow: 1;
    }

    .avatar-container {
      max-width: 30px;
    }

    .who {
      display: none;
    }
  }

  .moment {
    //flex-grow: 1.3;
    margin-left: 35px;
    min-width: 98px;
  }
}

.flow-header {
  .fa-paperclip {
    padding: 10px 0px;
  }
}

.flow-list__contents__subject__attachment {
  color: $color-flow-content-secondary;
  padding-left: 0;
  .fa-paperclip {
    margin-top: 0;
  }
}
