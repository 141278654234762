.hidden-button {
  visibility: hidden;
}

div.category-dialog {
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translate(-50%);
  width: auto;
  height: auto;
  min-width: 600px;
  min-height: 300px;
  padding: 25px;
}
