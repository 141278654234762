@keyframes updated-setting-fade {
  from {
    background-color: forestgreen;
  }

  to {
    background-color: transparent;
  }
}

.updated-setting {
  animation-name: updated-setting-fade;
  animation-duration: 4s;
  animation-fill-mode: forwards;
}

#settings-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
