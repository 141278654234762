@import '../../app.scss';
.flow-history-step-reassignment {
  background-color: $history-background;
  border: 1px solid $block-border-color;
  border-top: none;
  min-height: 55px;
  line-height: 20px;
  padding-top: 10px;
  padding-left: 70px;
  position: relative;

  .avatar-container {
    top: 13px;
    margin-top: -20px;
  }

  .avatar {
    left: 25px;
  }

  .time-divider {
    padding: 0;
    line-height: 1rem;
    text-align: center;
    position: relative;
    display: block;
    margin-top: -20px;
    margin-left: -70px;

    .time-divider-label {
      background: #fafafa;
      padding: 0.25rem 0.75rem;
      display: inline-block;
      margin: 0 auto;
      position: relative;
      border-radius: 1rem;
    }
  }

  .text {
    margin-left: -10px;
  }

  /* MOBILE ///////////////////////////////////////////////////////////// */
  .flow-history-step-reassignment.isMobile {
    padding-left: 45px;
    padding-top: 5px;

    .text {
      line-height: 16px;
      padding-top: 10px;
      padding-bottom: 2px;
      margin-right: 5px;
    }

    .avatar {
      left: 40px;
      top: 10px;
    }
  }
}
