.AddUsers-addAnotherUser__link {
  cursor: pointer;
  padding-left: 10px;
}

#userDetails .firstname,
#userDetails .lastname {
  width: 30%;
  padding: 10px 10px 0 0 !important;
}

#userDetails .email {
  width: 40%;

  input {
    width: 89%;
  }
}

.delete {
  position: absolute;
  cursor: pointer;
  font-size: 22px;
}
