@import '../app.scss';

.user-avatar-image {
  background-size: cover;
  background-position: center center;
}

.usermenu-expand {
  margin-top: -5px;
}

.user-menu__back-button {
  display: inline-block;
  position: absolute;
  left: 70px;
  z-index: 999;
  top: 0;

  #back-button {
    float: left;
  }
}

.user-menu__back-text {
  font-size: 20px;
  margin-left: 10px;
  display: inline-block;
  margin-top: 5px;
  max-width: 54vw;
  overflow: hidden !important;
  text-overflow: ellipsis;
  white-space: nowrap;

  &.isMobile {
    display: none;
  }
}

.user-profile-box__footer {
  align-items: stretch;
  justify-content: space-between;
}

.user-profile-box__profile-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 2;
}

.fa-magnifying-glass {
  z-index: 1;
}

.dropdown-content.active {
  display: block;
  width: 300px;
  opacity: 1;
  position: absolute;
  top: 56px;
  right: 10px;
  /*Firefox specific*/
  overflow: hidden;
  box-shadow: 1px 1px 2px 1px #ccc;
}

.publish-flow-model .dropdown-content {
  left: auto !important;
  right: 0 !important;
  width: auto !important;
  min-width: 119px;
}

.blue-hover {
  &:hover {
    color: #2196f3 !important;
  }
}

.arrow-up {
  position: absolute;
  right: 15px;
  top: 47px;
  width: 0;
  height: 5;
  z-index: 1000 !important;
  margin-left: 258px;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 12px solid #ddd;
}

.dropdown-content,
.dropdown-content li,
.dropdown-content li:hover {
  box-shadow: none;
  background-color: transparent;
  border: none;
}

#user-dropdown > li {
  cursor: default;
}

.user-dropdown-content {
  background-color: #ddd;
  border-right: 1px solid #ddd;
  border-left: 1px solid #ddd;
  border-bottom: 1px solid #ddd;

  #user-role-names {
    display: flex;
    flex-direction: column;
  }
}

nav ul li:hover {
  background-color: transparent !important;
  cursor: pointer;
  color: $color-highlight-blue;
}

.user-profile-box {
  padding-top: 12px;
}

.delegation {
  margin-top: -4px;
}

.delegation:hover {
  background-color: transparent !important;
  cursor: default;
  color: #35384d !important;
}

.user-menu-search-item {
  &:hover {
    color: inherit;
    cursor: default;
  }

  .user-menu-search {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 25vw;
    min-width: 13em;
    margin-top: 3px;
    position: relative;

    @media (max-width: 584px) {
      min-width: 16em;
    }

    i {
      display: inline-block !important;
      height: 32px !important;
      line-height: 32px !important;
      color: grey !important;

      &:hover {
        cursor: pointer;
        color: $color-highlight-blue !important;
      }
    }

    input,
    input:focus {
      margin-bottom: 0;
      height: 32px;
      line-height: 32px;
      margin-left: -30px;
      padding-left: 30px;
    }

    .fa-times {
      position: absolute;
      right: 10px;
      font-size: 20px;
      color: $color-text-primary;
      font-weight: 400;
      cursor: pointer;
    }
  }
}

flow-user-menu > ul > li {
  padding-left: 20px;

  a {
    padding: 0px;
    line-height: 45px !important;
    cursor: pointer;
    color: $color-text-primary;

    &:hover {
      color: $color-highlight-blue !important;
    }

    i {
      line-height: 45px !important;
    }
  }
}

#tenant-switch .tenant-name {
  max-width: 20em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#usermenu-to-template-library-button {
  padding: 0px 10px;
  height: 35px;
  border-radius: 25px;
  margin-top: 5px;
  color: #1b5e20;
  font-weight: 600;
  i {
    margin-right: 10px;
    font-size: 20px;
    margin-top: -5px;
  }
  div {
    margin-top: -5px;
  }
}
