#processmap-view {
  margin: 50px 0.75em 0px;
  display: flex;
  justify-content: space-between;
  width: 99%;
  font-weight: 600;
  background: #fff;

  > div {
    border: 1px solid #ddd;

    #tabstrip,
    #tabstrip2 {
      > ul > li {
        text-align: center;
      }
    }

    #pmap-avatar {
      margin: 0px 5px 0px 0px;
      color: #fff;
      text-align: center;
      text-transform: uppercase;
      line-height: 30px;
      width: 30px;
      font-size: 13px;
      font-weight: normal;
      cursor: pointer;
      float: left;
    }
  }

  .pmap-headers {
    font-size: 12px;
    margin-top: 2px;

    div {
      padding-left: 5px;
    }
  }

  .pmap-details {
    font-size: 15px;

    div {
      padding-left: 5px;
      margin: 5px 0px;
      text-align: left;
      line-height: 30px;
    }
  }

  .pmap-headers,
  .pmap-details {
    div {
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .left-side {
    width: 66.5%;

    .pmap-headers-and-details {
      width: 100%;
    }

    li[disabled] {
      pointer-events: none;
      opacity: 0.8;
    }

    .pmap-dynamic-height-with-abs-elem {
      position: relative;

      .flow-model {
        max-height: none;

        canvas {
          max-height: none;
        }
      }

      .edit-or-view-flowmodel-btn {
        margin: 0px 15px 15px 0px;
        //default: position:absolute + properties for position:absolute;
        position: absolute;
        bottom: 0px;
        right: 0px;
        z-index: 3;
        //position: relative;   - we are switching to this dynamically
        text-align: right;
      }
    }
    //feedback tab
    .flow-comments-container {
      border: none;
    }
  }

  .right-side {
    width: 32.5%;
    margin: 0px;

    #tabstrip2 {
      ul.k-tabstrip-items {
        margin-bottom: 0px;
      }
    }

    .input-field > label {
      left: 0.65rem !important;
      font-size: 16px;
      font-weight: 600;
      margin: 1rem 0rem;
      position: relative;
      display: block;
    }
  }

  li.k-state-default {
    background: #fff !important;
  }

  li.k-state-active {
    background: #eee !important;
  }

  .pmap-dynamic-height > div {
    overflow-x: auto;
  }
}
