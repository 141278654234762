@import '../../app.scss';
.single-flow-view {
  li[disabled] {
    pointer-events: none;
    opacity: 0.8;
  }

  &.isMobile {
    margin: 0;
  }

  .flow-header {
    box-shadow: none;
    border: 1px solid $block-border-color;
    border-bottom: none;
  }

  .flow-list__column-title {
    margin-top: 58px;
  }

  .flow-list__contents__due-date__due-by {
    margin-bottom: 0px !important; //@TODO classses are too mixed up
  }

  .flow-list__contents__subject__text {
    font-size: 14px;
    padding-right: 12.5px;
    overflow-wrap: anywhere;
  }

  .flow-list__contents__process-owner__text {
    font-size: 14px;
    padding-right: 12.5px;
  }
}

#start-flow-form {
  [role='heading'] {
  }

  .content {
    max-width: 980px;
    margin: 0px;
  }

  tr > * {
    padding: 5px 0 0 4px;
  }

  th {
    width: 118px;
    text-align: right;
  }

  td {
    vertical-align: top;
  }
}

.single-flow-view__tab {
  border-left: 1px solid $block-border-color;
  border-right: 1px solid $block-border-color;

  li {
    text-align: center;
  }
}

.single-flow-view__contents {
  p {
    margin: 0;
    line-height: 16px;
  }
}

#back-button {
  color: #808080;
  cursor: pointer;
  transition-property: margin-left, color;
  transition-duration: 0s;
  transition-timing-function: initial;
  transition-delay: initial;
  margin-right: 2px;
  vertical-align: top;
  margin-top: 10px;
  font-size: 20px;

  &:hover {
    color: $color-highlight-blue;
    margin-left: -2px;
    margin-right: 4px;
  }
}

.flow-title {
  overflow-wrap: break-word;
  z-index: 999;
  display: inline-block;
  max-width: 29vw;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  height: 26px;
  padding-left: 15px;
  position: fixed;
  top: 2px;
  left: 50px;
  background-color: white;

  &.isMobile {
    font-size: 15px;
    position: fixed;
    max-width: 46vw;
    padding: 0px;
    margin: 0px;
    left: 60px;
    top: 12px;
  }
  //iPad and iPadPro - portrait
  @media screen and (width: 1024px) and (min-height: 768px) and (max-height: 1366px) {
    &.isMobile {
      max-width: 34vw;
    }
  }
  //iPad - landscape
  @media screen and (width: 768px) and (height: 1024px) {
    &.isMobile {
      max-width: 24vw;
    }
  }
  //iPadPro - landscape
  /*@media screen and (width: 1366px) and (height: 1024px) {
        &.isMobile {
            padding-left: 215px;
            max-width: 48vw;
        }
    }*/

  #back-button {
    margin-top: 0;
  }
}

.flow-title-extend {
  max-width: 48vw;

  &.isMobile {
    max-width: 66vw;
  }
  //iPad and iPadPro - portrait
  @media screen and (width: 1024px) and (min-height: 768px) and (max-height: 1366px) {
    &.isMobile {
      max-width: 39vw;
    }
  }
  //iPad - landscape
  @media screen and (width: 768px) and (height: 1024px) {
    &.isMobile {
      max-width: 29vw;
    }
  }
  //iPadPro - landscape
  @media screen and (width: 1366px) and (height: 1024px) {
    &.isMobile {
      max-width: 45vw;
    }
  }
}

@media (max-width: 1705px) {
  .flow-title {
    max-width: 45vw;
  }
}

@media (max-width: 1480px) {
  .flow-title {
    max-width: 35vw;
  }
}

@media (max-width: 1160px) {
  .flow-title {
    max-width: 30vw;
  }
}

@media (max-width: 1040px) {
  .flow-title {
    padding: 0px;
    left: 60px;
    max-width: 12vw;
  }
}

@media (max-width: 600px) {
  .flow-title {
    padding: 0px;
    left: 60px;
    max-width: 50vw;
  }
}

.flow {
  margin: 0 0 0 15px;
  background-color: $default-background-color;
  max-width: $max-width-contents;

  .flow-header {
    /*min-height: $block-height-expanded;
        line-height: $block-line-height-expanded;*/
    font-size: $font-size-large;
    cursor: default;

    .col-1 {
      padding-left: 60px;
    }
  }

  .avatar {
    height: $block-header-avatar-width-expanded;
    line-height: $block-header-avatar-width-expanded;
    width: $block-header-avatar-width-expanded;
    left: 20px;

    p {
      line-height: $block-header-avatar-width-expanded;
      font-size: calc($block-header-avatar-width-expanded / 2);
      font-weight: normal;
    }

    img {
      height: $block-header-avatar-width-expanded;
      width: $block-header-avatar-width-expanded;
    }
  }

  .flow-comments-container {
    background-color: #fafafa;
    border: 1px solid #ddd;
    border-top: none;
  }

  &.isMobile {
    margin-top: 60px !important;

    .flow-header .col-1 {
      padding-left: 35px;
    }

    .flow-form-footer .btn {
      padding: 0 13px;
    }

    .avatar {
      img {
        //dont increase ige on mobile when expanded
        height: $block-header-avatar-width;
        width: $block-header-avatar-width;
      }
    }
  }

  .k-content {
    padding: 0px;
    margin: 0px;
    border: 0 !important;
  }

  .k-item {
    background-color: white !important;
  }

  .k-state-active {
    background-color: white !important;
  }

  .k-tabstrip-items .k-state-default {
    background-color: white !important;
    text-align: center;
  }

  .k-tabstrip-items .k-state-active {
    background: #eee !important;
  }

  .k-tabstrip-items {
    border-bottom: thin solid #ddd !important;
    background-color: $color-grey-lighter-80;
  }

  .k-tabstrip {
    background: #fafafa;

    .k-tabstrip-items {
      margin-bottom: 0;
    }
  }
}

.k-content.k-state-active {
  border: 1px transparent;
}

.flow-container {
  border-top: 1px solid #ddd;
  overflow-y: hidden;

  [type='radio']:checked + label:after {
    top: 2px;
    left: 2px;
  }

  [type='checkbox'].filled-in:checked + label:before {
    top: -2px;
    left: -2px;
  }
}

.flow__flow-model {
  position: relative;
  border: 1px solid $block-border-color;
  border-top: none;
}

/*#start-flow {
    [role=tabpanel] {
        border: thin solid #ddd !important; 
        position: relative; 
        top: 4px;
    }
}*/
/* MOBILE ////////////////////////////////////////////*/
.flow.isMobile {
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.16),
    0px -5px 5px 0px rgba(0, 0, 0, 0.16);
  margin: 0 10px;

  .avatar {
    height: $block-header-avatar-width;
    line-height: $block-header-avatar-width;
    width: $block-header-avatar-width;

    p {
      line-height: $block-header-avatar-width;
      font-size: calc($block-header-avatar-width / 2);
      font-weight: normal;
    }
  }

  .content {
    & > * {
      margin: 1em 0;
    }
  }
}

.splitter {
  width: 15px;
  cursor: e-resize;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .dragger-icon-color {
    color: #ece9e9 !important;
  }

  .icon-circle [class*='fa-'] {
    background-color: white;
    border-radius: 50%;
    color: #fff;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    margin: auto 3px;
    width: 40px;
    font-size: 18px;
    text-align: center;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    position: relative;
    z-index: 1;
  }
}

.pane-header {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin: -10px 0 5px 0;
  text-align: left;
  word-break: break-word;
  width: 100%;
}

.flow-icon-square-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60px;
  min-width: 60px;
}

.splitter-container .pane-right {
  overflow-y: unset;
}

.pane-right-header {
  text-align: center;
}

.pane-right-content {
  height: 100%;
  margin-right: 15px;
  overflow-y: auto;
}
