flow-report-list-header {
  .tags {
    width: 6em;
    margin-top: 4px;
    .chip {
      height: 18px;
    }
  }
}

.flow-list__contents__subject__category {
  font-weight: 600;
  font-size: 12px;
  color: #97a8b1;
  line-height: 16px;
  margin-bottom: 15px;
  overflow-wrap: anywhere;
}
