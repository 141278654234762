@import '../app.scss';
.start-category-list {
  margin-top: 20px;
}

@media only screen and (max-width: 600px) {
  .start-category-list {
    margin-top: 60px !important;
    padding-top: 0;
  }
}

.isMobile.start-category-list {
  margin-top: 20px !important;
}

.flow-start {
  overflow: hidden;
  position: relative;
  background-color: #fff;
  min-height: $block-height;
  padding: 0;
  font-size: $font-size-normal;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  //start with no top/bottom margins - we will add some and reduce the side margins when item is clicked
  //this will make it expand, but have space between it and adjacent flows.
  margin: 0 10px 0;

  .flow-start-header {
    h6 {
      span {
        /* when NOT expanded, use ellipsis for long text*/
        //text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      display: flex;
      flex-direction: row;
      padding-left: 10px;
      padding-top: 10px;
      font-size: $font-size-normal;

      b {
        display: flex;
        flex: 0 1 auto;
      }

      span {
        display: flex;
        flex: 1;
        padding-left: 5px;
      }
    }

    .flow-start-mobile {
      width: 100%;
    }

    .flow-list__contents__subject {
      margin-left: 2%;
    }

    .flow-list__contents__process-owner {
      margin-left: 2%;
    }

    .flow-list__contents__due__type--mobile {
      margin-bottom: 15px;
    }
  }

  .badge-container {
    position: absolute;
    right: 10px;
    top: 3px;
  }

  .flow-start-body {
    position: relative;

    & > div {
      position: relative;
    }

    .avatar {
      position: absolute;
      line-height: 30px;
      top: 5px;
      left: 20px;
    }
  }

  .flow-start-footer {
    padding: 5px 10px 10px;
    min-height: $block-height;
    line-height: $block-height;
    background-color: white;

    button {
      float: right;
      margin-top: 5px;
    }
  }

  .wrap {
    overflow: hidden;

    h6 {
      float: left;
      display: inline-block;
    }
  }

  &.expanded {
    margin: 20px 0px;
    transition: margin 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.36),
      0 2px 10px 0 rgba(0, 0, 0, 0.32);

    .flow-start-header {
      h6 {
        font-size: $font-size-large;

        span {
          display: flex;
          /* remove ellipsis for long text when expanded */
          overflow: initial;
          white-space: initial;
        }
      }
    }
  }

  &.isMobile {
    margin: 0;

    span {
      display: block;
    }

    &.expanded {
      margin: 10px 5px;
    }

    .flow-start-header {
      line-height: 20px;
    }

    .flow-start-body {
      .avatar {
        top: 15px;
        left: 10px;
      }

      .subject {
        display: block;
        margin-top: 10px;
      }

      label {
        font-size: $font-size-normal;
      }
    }
  }
}

.flow-start__flow-model {
  position: relative;
}

.start-flow-content {
  margin: 20px 0 20px 85px;
}

.flow-start__column-title__contents__flow-name {
  width: 14%;
  margin-left: 1%;
}

.flow-start__column-title__contents__subject {
  width: 36.5%;
}

.flow-start__column-title__contents__current-step {
  width: 31%;
}

.grayed-out {
  color: #ddd;
}

.start-flow-content-wrapper,
.start-flow-actors-text,
.start-flow-actors,
.start-flow-subject,
.start-flow-fields {
  float: left;
}

.start-flow-content-wrapper {
  width: 100%;
}

.start-flow-actors-text,
.start-flow-subject {
  width: 110px;
  text-align: right;
  margin-top: 10px;
  margin-right: 20px;
}

.start-flow-actors,
.start-flow-fields {
  width: calc(100% - 130px);
}

.start-flow-subject__label,
.start-flow-actors-text__label {
  font-weight: 600;
}

.start-flow-fields {
  input[type='text'] {
    margin-bottom: 5px;
  }

  button[type='submit'] {
    margin-top: -5px;
  }
}

.start-flow-actors {
  margin-bottom: 10px;
}

.start-flow-actors__actor {
  float: left;
  margin-right: 10px;
  margin-bottom: 10px;

  .avatar {
    position: relative !important;
    float: left;
    left: 0 !important;
    margin-right: 5px;
  }
}

.start-flow-actors__name {
  margin-top: 14px;
  display: inline-block;
}

.start-flow-actors__link {
  margin-top: 0px; // we start with 0px so it aligns with the other inline elements
  // then when its followed by a start-flow-actors__actor it returns to 14px;
  display: inline-block;
}

.start-flow-actors__actor + .start-flow-actors__link-wrapper {
  .start-flow-actors__link {
    margin-top: 14px;
  }
}

.start-flow-actors__link {
  color: $color-highlight-blue;
  cursor: pointer;
}

.start-flow-actors__link-wrapper {
  float: left;
}
