$grid-filter-border-radius: 5px;

.runner-report-alldata {
  position: relative;
  margin: 0 25px;
  padding-top: 0px;
  max-width: unset !important;
  overflow: hidden;
  z-index: 0;
  clear: both;
  margin-bottom: 1em;
  top: -20px; // see FLOW-4682
  .report-datepicker {
    height: 6em;
    min-width: 936px;

    .row {
      float: left;
      padding-right: 3em;
    }

    &::after {
      content: '';
      clear: both;
      display: block;
    }

    label {
      display: block;
      font-weight: 600;
    }
  }

  .report-filter-input {
    width: 120%; // some effed up calculations here
  }
}

.k-loading-mask {
  display: none;
}

.k-grid {
  .k-grid-header {
    .k-grid-header-wrap,
    .k-grid-header-locked {
      th.k-header {
        vertical-align: middle;
        font-weight: 600;

        > .k-link {
          text-overflow: initial;
          font-weight: 600;
        }
      }

      .k-filter-row {
        input[type='text'] {
          border-radius: $grid-filter-border-radius;
        }

        .k-combobox {
          input[type='text'] {
            border-radius: $grid-filter-border-radius 0 0
              $grid-filter-border-radius;
          }

          span.k-select {
            border-radius: 0 $grid-filter-border-radius
              $grid-filter-border-radius 0;
          }
        }
      }
    }
  }

  .k-grid-content {
    tr {
      background-color: #fafafa;

      td {
        vertical-align: top;
      }
    }

    tr:hover {
      background-color: #e6f3fd;
    }
  }

  .k-grid-content-locked {
    tr {
      background-color: #fafafa;

      td {
        vertical-align: top;
      }
    }
  }
}

.runner-grid-default {
  border: none;
  margin: 20px 25px 0;

  .k-grid-header {
    .k-grid-header-wrap {
      border: none;

      th.k-header {
        border: none;
        padding-bottom: 5px;
      }

      .k-filter-row {
        th {
          border: none;
          padding-top: 0;
        }

        span {
          border: none;
        }
      }
    }
  }
}

// these css control the header search inputs
#reportGrid {
  .k-operator-hidden {
    padding-right: 0;

    [aria-label='Clear'] {
      display: none;
    }
  }
}

#reportGrid {
  td,
  th {
    border-left: thin solid #ece9e9;

    &:last-of-type {
      border-right: thin solid #ece9e9;
    }
  }
  // this is to limit the "height" of the columns
  td[role='gridcell'] {
    padding-top: 0;
    padding-bottom: 0;

    & > * {
      overflow: hidden;
      display: block;
      text-overflow: ellipsis;
      word-break: break-all;
      white-space: nowrap;
      line-height: 35px;
    }
  }

  background-color: #fafafa;
  // these properties are carried over from the original kendo common.min.css
  // however they only apply to actual kendo initialized widgets so we copy over here
  .k-inputlike {
    line-height: 2.07142857em;
    height: 2.07142857em;
    padding: 0.5em 0;
    text-indent: 0.8em;
    border: 0;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
  // the positioning and relativiely of the elements are fucked
  // so we must base it relative to the viewport instead
  .k-grid-content {
    max-height: calc(100vh - 390px);
  }
  // to align locked column with other columns
  .k-grid-header-locked {
    .k-filter-row {
      height: 60px;
    }
  }
}

.reportToolbar {
  hr {
    border-top: 1px solid grey;
  }

  .k-menu-expand-arrow {
    display: none;
  }

  .k-link {
    padding: 0em !important;
  }

  .k-menu {
    background-color: transparent;

    .k-item.k-state-default.k-state-focused {
      box-shadow: none;
    }
  }

  .k-menu .k-animation-container .k-menu-group {
    margin-left: -13px !important;
  }

  .li-selectall {
    margin-left: 5px;
    padding-bottom: 5px;
  }

  [type='checkbox'].filled-in + label:after {
    height: 10px !important;
    width: 10px !important;
    background: #fff !important;
    border: 2px #35384d solid !important;
    margin-top: 5px !important;
  }

  [type='checkbox']:checked + label:before {
    width: 1px !important;
    height: 4px !important;
    left: 2px !important;
    top: 4px !important;
    border-bottom: 2px #35384d solid !important;
    border-right: 2px #35384d solid !important;
    background: #fff !important;
    margin-top: 4px !important;
  }
}

table#reportView tr td {
  border: none !important;
  padding-top: 0;
  padding-bottom: 0;
}

.runner-page-header-report {
  h4 {
    margin-left: 30px;
  }
}
