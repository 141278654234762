/* TODO replace with custom styles */

/* in order to get the if-permissions directive to work alongside the ng-include I needed to nest the ng-include
    this broke some styles since the styles are very explicit, considered updating to simpler styles, but the yaybar functionality we use
    for sidebar open / close etc is tightly coupled to the classes (.e.g yaybar etc)
    So, just overriding where needed.
*/

$active-blue: #42a5f5;
$flowingly-orange: #f15f22;
$width-side-menu-default: 205px;
$width-side-menu-desktop: 62px;
$width-side-menu-mobile: 205px;
$width-brand-logo: 115px;
$height-brand-logo: 26px;
$width-toggle-menu-button: 18px;

.flow-sidebar {
  .yaybar .nano-content > ul li > div {
    > a {
      display: block;
      cursor: pointer;
      color: #ffffff;
      padding-top: 10px;
      margin-bottom: 10px;
      line-height: 20px;
      border-bottom: none;
      background: 0 0;
      text-decoration: none;
      z-index: auto;
      -webkit-transition: none;
      transition: none;
      text-align: center;
      font-size: 10.45px;
      width: $width-side-menu-desktop;

      i {
        font-size: 21px;
      }
    }

    > a.mobile {
      padding: 10px 15px;
      margin: 0px;
      text-align: left;
      font-size: 15px;
      width: $width-side-menu-mobile;

      i {
        font-size: 15px;
        margin-top: -3px;
      }
    }
  }
  //hover item
  .yaybar .nano-content > ul > li:hover {
    background: $active-blue;
  }
  //active item
  .yaybar .nano-content > ul > li.active > div > .sp-cancel,
  .yaybar .nano-content > ul > li.active > div > a {
    background: $active-blue;
  }
  //dropdown items
  .yaybar .nano-content > ul li.open li a {
    font-size: 13px;
    background: #35384c;

    &:hover {
      background: $active-blue;
    }
  }

  .yaybar .nano-content > ul li > .sp-cancel > div > .badge,
  .yaybar .nano-content > ul li > div > a > .badge {
    float: right;
    font-weight: 400; //600;
    font-size: 0.9rem;
    color: #fff;
    //background-color: #42A5F5;
    height: auto;
    line-height: 1;
    border-radius: 10px;
    min-width: 10px;
    padding: 3px 7px; //0px 0px 3px 7px;
  }

  .yaybar.menu-desktop-shrunk-menu .nano-content > ul > li > div > a > .badge,
  .yaybar.menu-desktop-shrunk-menu
    .nano-content
    > ul
    > li
    > .sp-cancel
    > .badge {
    position: absolute;
    right: 3px;
    top: 3px;
  }

  span.badge {
    position: relative;
    right: auto;
    font-weight: 400;
    font-size: 0.9rem;
    color: #fff;
    background-color: $active-blue;
    height: auto;
    line-height: 1;
    border-radius: 10px;
    min-width: 10px;
    padding: 3px 7px;
  }

  .badge-todo-desktop {
    position: relative;
  }

  .badge-todo-desktop[data-badge]:after {
    content: attr(data-badge);
    position: absolute;
    top: 1px;
    right: 5px;
    font-size: 12px;
    font-weight: 600;
    color: #1b5e20;
    background-color: #e8f5e9;
    min-width: 18px;
    padding: 0px 4px;
    height: 18px;
    line-height: 18px;
    border-radius: 9px;
  }

  /* end overrides */
  .yay-shrink ~ .content-wrap {
    margin-left: 0;
  }

  .yay-shrink .brand-logo {
    left: 130px;
  }

  .yay-hide-to-small.yay-shrink ~ .content-wrap {
    margin-left: 60px;
  }

  .editProfileDropdownLink a:hover {
    color: #2196f3 !important;
  }

  .dummyStyleToForceChange {
    color: red;
  }
  /* because we are using our own class (menu-desktop-shrunk-menu)  and  not using (.yah-hide on body) we need to incude
        these styles here for modeler hover menu to work when menu is shrunnk */
  .yaybar.menu-desktop-shrunk-menu,
  .yaybar.menu-desktop-shrunk-menu .nano,
  .yaybar.menu-desktop-shrunk-menu .nano-content {
    overflow: visible;
  }

  .yaybar.menu-desktop-shrunk-menu .nano-content > ul li:hover > ul {
    display: block !important;
    visibility: visible;
  }

  .yaybar.menu-desktop-shrunk-menu .nano-content > ul {
    width: 60px;
    min-width: 60px;
  }

  .yaybar.menu-desktop-shrunk-menu ~ .content-wrap,
  .yaybar.menu-desktop-shrunk-menu ~ footer,
  .yaybar.menu-desktop-shrunk-menu ~ .load-spinner,
  .yaybar.yay-overlay.menu-desktop-shrunk-menu ~ .content-wrap,
  .yaybar.yay-overlay.menu-desktop-shrunk-menu ~ footer,
  .yaybar.yay-overlay.menu-desktop-shrunk-menu ~ .load-spinner {
    margin-left: 60px;
  }

  .yaybar.yay-shrink.menu-desktop-shrunk-menu ~ .content-wrap,
  .yaybar.yay-shrink.menu-desktop-shrunk-menu ~ footer,
  .yaybar.yay-shrink.menu-desktop-shrunk-menu ~ .load-spinner {
    margin-left: 60px;
  }

  .yaybar.menu-desktop-shrunk-menu .nano-content > ul > li > div > a,
  .yaybar.menu-desktop-shrunk-menu .nano-content > ul > li > div > .sp-cancel {
    padding: 0;
    text-align: center;
    padding: 12px 0;
    line-height: 1;
  }

  .yaybar.menu-desktop-shrunk-menu .nano-content > ul > li > div > a > i,
  .yaybar.menu-desktop-shrunk-menu
    .nano-content
    > ul
    > li
    > div
    > .sp-cancel
    > i {
    width: auto;
    font-size: 1.7rem;
  }

  .yaybar.menu-desktop-shrunk-menu .nano-content > ul > li > div > a,
  .yaybar.menu-desktop-shrunk-menu .nano-content > ul > li > .sp-cancel,
  .yaybar.menu-desktop-shrunk-menu
    .nano-content
    > ul
    > li
    > a
    > .yay-collapse-icon,
  .yaybar.menu-desktop-shrunk-menu
    .nano-content
    > ul
    > li
    > .sp-cancel
    > .yay-collapse-icon {
    font-size: 0;
  }

  .yaybar.menu-desktop-shrunk-menu .nano-content > ul > li > div > a > .badge,
  .yaybar.menu-desktop-shrunk-menu
    .nano-content
    > ul
    > li
    > .sp-cancel
    > .badge {
    position: absolute;
    right: 3px;
    top: 3px;
  }

  .yaybar.menu-desktop-shrunk-menu .nano-content > ul > li {
    position: relative;
  }

  .yaybar.menu-desktop-shrunk-menu .nano-content > ul > li > ul {
    visibility: hidden;
    width: 240px;
    position: absolute;
    top: 0;
    left: 100%;
  }

  .yaybar.menu-desktop-shrunk-menu .nano-content > ul li:hover > ul {
    display: block !important;
    visibility: visible;
  }

  .hamburger div.burg1,
  .hamburger div.burg3 {
    -webkit-transform: translate3d(0, 0, 0) rotate3d(0, 0, 1, 0deg);
    transform: translate3d(0, 0, 0) rotate3d(0, 0, 1, 0deg);
  }

  .hamburger div.burg2 {
    opacity: 1;
  }

  .close-cross div.burg1 {
    -webkit-transform: translate3d(0, 6px, 0) rotate3d(0, 0, 1, -45deg);
    transform: translate3d(0, 6px, 0) rotate3d(0, 0, 1, -45deg);
  }

  .close-cross div.burg3 {
    margin-bottom: 0;
    -webkit-transform: translate3d(0, -6px, 0) rotate3d(0, 0, 1, 45deg);
    transform: translate3d(0, -6px, 0) rotate3d(0, 0, 1, 45deg);
  }

  .close-cross div.burg2 {
    opacity: 0;
  }
}

// LHS menu style start
.yaybar {
  width: $width-side-menu-desktop;

  i {
    margin-right: 0px;
  }

  .top {
    height: 77px;

    .brand-logo {
      width: $width-brand-logo;
      height: $height-brand-logo;
      padding: 0;
      margin-top: 14px;

      img {
        width: $width-brand-logo;
      }
    }

    > div {
      width: $width-side-menu-desktop;
    }

    .yay-toggle {
      div {
        width: $width-toggle-menu-button;
      }
    }

    .brand-logo-desktop {
      justify-content: center;
    }

    .brand-logo-desktop .brand-logo-image {
      width: 23px;
      height: 26px;
      background-size: cover;
      margin-top: 5px;
    }

    .brand-logo-desktop .flussos-brand-logo {
      width: 50px;
      height: 50px;
      background-size: cover;
      margin-top: 5px;
    }
  }

  &.mobile {
    width: $width-side-menu-mobile;

    .top {
      height: initial;

      > div {
        width: $width-side-menu-default;
      }
    }
  }
  /*Fix for the scroll bar appearing for runner in IE11 and FireFox*/
  .nano {
    > .nano-content {
      overflow: auto !important;
      width: calc(100% + 20px);
      //when a menu item is active (and hilighted in blue) darken the badge
      > ul {
        min-width: $width-side-menu-desktop;

        li.active > a > .badge {
          background-color: #35384c;
        }
      }
    }

    > .nano-content.mobile {
      width: initial;
    }
  }
}

/* applied to element with yaybar class */
.hide-menu-menu {
  left: -240px;
}

.hide-menu-content {
  margin-left: 0px;
}
/* applied to element with content-wrap class */
.menu-mobile-content {
  margin-left: 0px;
}
/* applied to element with yaybar class */
.menu-mobile-content-menu {
  left: -240px;
}

/* applied to element with yaybar class */
.menu-desktop-pinned-menu {
  margin-left: 0px;
  overflow: visible;
}
/* applied to element with content-wrap class */
.menu-desktop-shrunk-content {
  margin-left: 60px;
}
/* applied to element with yaybar class */
.menu-desktop-shrunk-menu {
  overflow: visible;
  position: absolute;
  width: 60px;
  left: 0;
}
/* applied to element with content-wrap class */
.menu-desktop-hidden-content {
  margin-left: 0;
}
/* applied to element with yaybar class */
.menu-desktop-hidden-menu {
  overflow: visible;
  left: -240px;
}

/* remove extra padding on mobile*/
@media (max-width: 600px) {
  .hide-menu-content,
  .menu-mobile-content {
    padding-left: 0px;
    padding-right: 0px;
    margin-left: -10px;
  }
}

.delegationMode {
  background: #f7f5b0;
}

.hide-badge::after {
  display: none;
}
