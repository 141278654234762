$grayed: #a9a9a9;

.pmap-step-form-tab {
  margin: 2%;
  overflow-wrap: break-word;

  /*display: flex;
    flex-direction: column;
    min-height: 300px;
    margin-top: 10%;

    .grayed {
        color: $grayed;
    }*/
  input {
    pointer-events: none !important;
  }
}
