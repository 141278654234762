.v2-view-breadcrumbs {
  &-outer-container {
    position: relative;
  }

  &-container {
    display: inline-flex;
    overflow: hidden;
    align-items: center;
    justify-content: flex-start;
  }

  &-inner-container {
    justify-content: center;
    display: flex;
  }

  &-button-container {
    display: flex;
    align-items: center;
  }

  &-button-left {
    position: absolute;
    left: 0;
    width: 5%;
    padding-left: 40px;
  }

  &-button-right {
    position: absolute;
    right: -30px;
    width: 5%;
  }

  &-width-90 {
    width: 90%;
  }

  &-width-100 {
    width: 100%;
  }
}

.v2-view-breadcrumb-hr {
  margin: 0;
}
