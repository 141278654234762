.flow-model-owner-dialog {
  .smart-select-search {
    h4.row-title {
      display: none;
    }
  }

  .smart-select-list .selected-list {
    margin-top: -1px;
  }

  ul.k-tabstrip-items li {
    &.k-state-active,
    &.k-state-default {
      background-color: #fff !important;
    }
  }
}

.public-sharing-container {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.public-sharing-textarea {
  resize: none;
  width: 100%;
  height: auto;
  padding: 8px;
  margin-top: 8px;
}

.public-sharing-button,
.public-sharing-button:focus {
  position: absolute;
  right: 2px;
  top: 0.75rem;
  margin: auto;
  border: none;
  height: 25px;
  background-color: #fff;
}

.public-sharing-icon-container {
  font-size: 18px;
}

.public-sharing-toggle-container {
  i {
    font-size: 18px;
  }
  display: flex;
  align-items: center;
}

.public-sharing-toggle-margin {
  margin-left: 10px;
  margin-top: 10px;
}

.public-sharing-label {
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.embed-code-container,
.public-url-container {
  margin-top: 1rem;
  position: relative;
}

.embed-code-container {
  margin-bottom: 3rem;
}

.public-sharing-disabled {
  opacity: 0.5;
}

.public-sharing-type-radio-button-container {
  margin-left: -4px;
  margin-top: 4px;
  margin-bottom: 1rem;
}

.overflow-visible {
  overflow: visible !important;
}
