@import '../app.scss';
.flows-todo-groups {
  .flow-list {
    margin-left: 25px;
    margin-right: 25px;
  }
}

.flow-list {
  ul {
    list-style: none;
  }
}

.todo-list-header {
  h2 {
    color: #999;
    font-size: 16px;
    margin-left: 40px;
  }
}
.todo-groups {
  margin: 0;

  label {
    color: #999;
    font-size: 14px;
    padding-bottom: 0;
  }
  .label {
    text-align: left;
    padding-bottom: 0;
  }
  .select {
    padding-bottom: 0;
  }
}

.todo-flows-select {
  min-width: 200px;
  max-width: 350px;
  float: right;
  margin-right: -10px;
  z-index: 1;
  position: relative;
}

.dropdown-filter-align {
  margin-top: -70px;
}

.todo-filter-text-color {
  color: $flow-icon-square-bg-color;
}

.due-soon-filter-text-color
  .due-today-filter-text-color
  .all-flows-filter-text-color {
  color: $color-text-primary;
}

.overdue-filter-text-color {
  color: $color-redish;
}

@media only screen and (max-width: 600px) {
  .todo-groups {
    margin-top: 0;
  }
}
