$block-height: 40px;
$block-height-expanded: 50px;
$block-height-mobile: 100px;
$block-text-height-mobile: 22px;
$block-border-color: #ddd;
$block-header-avatar-width: 30px;
$block-header-avatar-width-expanded: 40px;
$block-header-padding-left-right: 10px;
$block-header-indent: 70px;
$block-header-indent-mobile: 35px;
$block-content-indent: 40px;
$history-block-height: 90px;
$history-background: #eee;
$history-content-secondary-color: #999;
$blue: #2196f3;
$green: #417505;
$pink: #ef9a9a;
$history-black: #555;
$font-size-large: 16px;
$font-size-normal-mobile: 14px;
$font-size-normal: 14px;
$font-size-history-form-mobile: 12px;

.ngdialog.ngdialog-theme-plain.ngdialog-report {
  .ngdialog-content {
    width: 50%;
    height: 100%;
  }
}
