@import '../app.scss';

user-settings {
  i {
    padding-top: 3px;
    padding-right: 5px;
  }

  .help-link {
    color: $color-primary-blue;
    cursor: pointer;
  }
}

#web-app-install-dialog {
  img {
    width: 100%;
    object-fit: contain;
    border: 1px solid #ccc;
    border-radius: 3px;
  }
}
