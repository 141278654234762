@import '../../../../../app.scss';
#category-edit-modal {
  .k-treeview {
    max-height: 300px;
  }
  .k-treeview-leaf.k-selected {
    color: $color-text-primary !important;
    background: #f5f5f5 !important;
  }
  .input-field {
    .select-approver-container {
      display: flex;
      align-items: center;

      .select-approver {
        flex-grow: 1;
        width: 100%;
      }
    }
  }
}
