@import '../../app.scss';
.flow-form {
  padding: 1px 10px;
  background-color: white;

  .fg-radio-button-list p:last-child {
    padding-bottom: 10px !important;
  }
  /* START tidy up alignment of form gen inputs */
  h4 {
    text-align: center;
    font-size: 26px;
  }

  h5 {
    text-align: center;
    font-size: 16px;
    font-style: italic;
  }

  .mnt-30 {
    margin-top: -30px;
  }

  .mnt-20 {
    margin-bottom: -20px !important;
  }

  .ln-10 {
    left: -10px !important;
  }
  /* enclose each form field in a wrapper to ensure consistent form spacing*/
  .form-field-wrapper {
    margin-top: 1.6rem;
    /*border: 1px solid red;*/
    display: block;
  }

  .clearfix::after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: ' ';
    clear: both;
    height: 0;
  }
  /* reset con input stylings as not all our controls are inputs*/
  .input-field {
    margin-top: 0px;
    display: block;
  }

  .input-field label {
    position: relative;
  }
  /* active is getting set somewhere (MaterializeJS?) remove the transform */
  .input-field label.active {
    transform: none;
  }

  .input-field label.fg-checkbox-label {
    top: 10px;
  }
  /* END tidy up alignment of form gen inputs */
  input {
    margin-bottom: 0;
  }

  .input-field input + label {
    width: auto;
    display: inline-block;
    padding-right: 40px;
  }

  .input-field label {
    display: block;
    position: relative;
    left: 0;
    font-weight: 600;
    line-height: 20px;
    top: 0;
  }
  /* active is getting set somewhere (MaterializeJS?) remove the transform */
  .input-field label.active {
    transform: none;
    font-weight: 600;
    line-height: 20px;
  }

  .input-field label.fg-checkbox-tick {
    display: inline-block;
  }

  .input-field [type='checkbox'] + label {
    height: auto;
  }

  .content {
    max-width: calc(100% - 15px);
    margin: 0 auto;
  }

  fieldset {
    border: none;
    margin: 0;
    padding: 0;
  }
  /* file upload label remove left margin */
  label.display-name {
    margin-left: -10px;
    padding-bottom: 5px;
    display: block;
  }

  ::-webkit-input-placeholder {
    font-style: italic;
  }

  :-moz-placeholder {
    font-style: italic;
  }

  ::-moz-placeholder {
    font-style: italic;
  }

  :-ms-input-placeholder {
    font-style: italic;
  }

  .alert {
    display: inline-block;
  }
}

.flow-form__contents__assistance {
  font-weight: 600;
  font-size: 12px;
  color: $color-flow-content-secondary;
  line-height: 16px;
  margin-bottom: 15px;
}

.flow-form__Approvals-remaining {
  font-size: 12px;
  font-style: italic;
  text-align: center;
}

.flow-form__Active-step-name {
  font-size: 20px;
}

.flow-form__Active-step-last-update {
  font-style: italic;
}

.flow-form__Active-step-name,
.flow-form__Active-step-last-update {
  text-align: center;
}

.flow-form.isMobile {
  padding-left: 10px;
  padding-right: 10px;
}

.flow-form-footer {
  min-height: $block-height;
  line-height: $block-height;
  background-color: white;

  label,
  a {
    margin: 0;
    margin-top: 5px;
    font: inherit;
    line-height: 36px;
  }

  a {
    font-weight: 500;
    color: #888;
    background: none;
    border: none;
    box-shadow: none;
    padding-right: 15px;
    padding-left: 0;

    &:hover {
      color: $blue;
      background: none;
      border: none;
      box-shadow: none;
    }
  }

  &.disable-footer-buttons {
    pointer-events: none;

    .flow-history-completed {
      pointer-events: auto;
    }
  }
}
