@import './app.scss';
.process-map-v2-view-step-details-italic-and-grayed {
  font-family: 'Open Sans', sans-serif;
  font-style: italic;
  color: $placeholder-color;
}

.process-map-v2-view-step-details-grayed {
  font-family: 'Open Sans', sans-serif;
  color: $placeholder-color;
}
