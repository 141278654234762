button.v2-custom-button {
  text-transform: none;
  color: #ffffff;
  background-color: #42a5f5;
  border-radius: 0.5rem;
  font-family: 'Open Sans', sans-serif;

  &:hover {
    background-color: #42a5f5;
    box-shadow: none;
  }

  box-shadow: none;
}
