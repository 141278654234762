@import '../../app.scss';
.flow-footer-history {
  background-color: $history-background;
  min-height: $block-height;
  line-height: 20px;
}

.flow-footer-command {
  padding: 5px 10px 10px 5px;
  background-color: white;

  .flow-history-waiting {
    background-color: white;
    min-height: $history-block-height;
    line-height: $history-block-height;
    padding-left: $block-header-indent;
    padding-top: calc($history-block-height / 10);
    margin-left: 5px;

    .avatar {
      top: 10px;
      left: 30px;
    }

    .time-divider {
      padding: 0;
      line-height: 1rem;
      text-align: center;
      position: relative;
      display: block;
      margin-top: -20px;
      margin-left: -70px;
    }

    .text {
      margin-left: -10px;
    }
  }
}

.flow-footer-command__withdraw--mobile {
  padding-left: 0;
}

.flow-form-footer--mobile {
  margin-top: 20px;
}
