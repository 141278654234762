$pdf-export-scale: 0.8;
.pdf-export {
  //TOP-MOST HEADERS
  .flow-column-title__flow-icon-square---todo--imin {
    width: 8%;
  }

  .flow-column-title__contents--todo--imin {
    .flow-column-title__flow-name--todo--imin {
      margin-left: 1%;
      width: 9%;
    }

    .flow-current-step__contents__due-date__text {
      width: 92%;
    }

    .flow-column-title__contents--todo--imin
      .flow-column-title__subject--todo--imin {
      width: 33%;
    }
  }
  //LEFT PANE
  .flow-history-container-leftpane {
    max-height: none;
  }

  .flow-header .fa-paperclip {
    position: inherit;
  }

  .single-flow-view .flow-list__contents__subject__text {
    font-size: 10px !important;
  }
  //RIGHT PANE
  .pane-right {
    height: auto;

    .flow-list__contents__due-date__due-by {
      font-size: 8px;
    }

    .flow-current-step__contents__due-date__text {
      font-size: 8px;
      line-height: 0px;
    }

    .flow-history-waiting .text {
      margin-left: -15px;
    }
  }

  #start-flow {
    width: 96.5%;
  }

  .flow-history-step {
    input + label,
    p {
      font-size: (13px * $pdf-export-scale) !important;
    }
  }

  .step-header {
    margin-top: 20px * $pdf-export-scale;
    font-size: (13px * $pdf-export-scale) !important;
  }

  .step-value {
    font-size: (13px * $pdf-export-scale) !important;
  }

  .flow-form__Active-step-name {
    font-size: (20px * $pdf-export-scale) !important;
  }

  .input-field label {
    font-size: (13px * $pdf-export-scale) !important;
  }

  flow-history-start + div .time-divider {
    display: none !important;
  }

  .flow-list__contents__subject__created-by,
  .flow-list__contents__subject__started-by,
  .flow-status-progress--text,
  .flow-list__contents__current-step__flowed-to,
  .flow-list__contents__due-date__due-by {
    font-size: 13px * $pdf-export-scale;
  }

  .secondary-text {
    font-size: 12px * $pdf-export-scale;
  }

  .flow-list__contents__process-owner__text,
  .flow-list__contents__flow-status__identifier,
  .flow-list__contents__flow-name__text,
  .flow-list__contents__subject__text,
  .flow-list__contents__description,
  .flow-list__contents__current-step__text,
  .flow-list__contents__flow-status__text,
  .flow-list__contents__due-date__text,
  .flow-header__extra__header,
  .flow-header__extra--start-flow {
    font-size: 15px * $pdf-export-scale;
  }
  // this is a hack fix for the checkbox.
  // in IE, the frontend pdf generator
  // does not work well with ::before/::after and
  // absolute positioning
  .pdf-export-checkbox-replacement {
    width: 20px;
    display: inline-block;
    text-align: center;
    font-size: 14px;
    color: #42a5f5;
  }
}

// fix for broken css after import
flow-container [disabled][is-checked='true'] + label:after {
  background: #bdbdbd !important;
  border: none;
  transform: none !important;
  width: 20px;
  height: 20px;
  display: block;
  z-index: 999;
  left: 0px !important;
  top: 0px !important;
}
