@import '../../app.scss';
.flow-list {
  max-width: $max-width-contents;
  margin: 0 15px;

  ul {
    list-style: none;
    padding: 0px;
    margin: 0;

    li {
      margin-bottom: 7px;
    }
  }

  &.isMobile {
    margin-left: 0 !important;
    margin-right: 15px !important;

    flow-icon-square span {
      line-height: 36px;
    }
  }
}

.flow-list__column-title--todo--imin {
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 20px;
  margin: 0px;

  .flow-list__column-title__contents__flow-name--todo--imin {
    width: 14%;
    margin-left: 1%;
  }

  .flow-list__column-title__contents__subject--todo--imin {
    width: 36.5%;
  }

  .flow-list__column-title__contents__current-step--todo--imin {
    width: 31%;
  }

  .flow-list__column-title__contents__due-date--todo--imin {
  }
}
