$readonly-border: #f0f0f0;

.pmap-process-details-tab {
  display: flex;
  flex-direction: column;
  margin: 2% 4%;
  overflow: auto;

  > div {
    display: flex;

    .process-details-label {
      font-weight: 600;
    }

    .process-details-data {
      margin-top: 10px;
    }

    .short-details {
      width: 33%;
    }

    .split {
      width: 50%;
    }
  }
}
