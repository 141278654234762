@import '../app.scss';
.select-user-combobox {
  &.k-input:not(:-webkit-autofill) {
    background: transparent;
    height: 50px;
  }

  &.k-input-solid,
  &.k-picker-solid {
    border-width: 0 0;
  }

  &.k-picker-md {
    .k-input-inner {
      height: 20px;
    }
  }
  &.k-input-solid:focus-within {
    border-color: grey;
  }
}

.k-animation-container {
  .k-selected {
    color: $color-text-primary !important;
    background: #ebebeb !important;
  }
}
