@import '../app.scss';
.flows-imin-group {
  .flow-list {
    margin-left: 25px;
    margin-right: 25px;
  }
}

.imin-flows-filtergroup {
  margin-bottom: -15px;
  max-width: 1245px;
}

.imin-flows-selectfilter {
  min-width: 200px;
  max-width: 300px;
  display: inline-block;
}

.flow-list {
  ul {
    list-style: none;
  }
}

.imin-list-header {
  h2 {
    color: #999;
    font-size: 16px;
    margin-top: 20px;
    margin-left: 40px;
  }
}
.imin-groupsandfilters {
  label {
    color: #999;
    font-size: 14px;
    padding-bottom: 0;
  }
  .label {
    text-align: left;
    padding-bottom: 0;
  }
  .select {
    padding-bottom: 0;
  }
}

.k-tabstrip-items .k-state-default {
  /*non-active state styles - borders and backgrounds*/
  color: $color-text-primary !important;
  background-color: #fafafa !important;
  text-transform: none;
  width: 33%;
}

.imin-tabctrl {
  .k-tabstrip-items .k-state-default {
    width: 150px;
  }
}

.single-flow-view__tab .k-state-default {
  background: #fff !important;
}

.k-tabstrip-items .k-state-default .k-link {
  /*non-active state styles - text color*/
  color: $color-text-primary !important;
  text-transform: none;
}

.k-tabstrip-items .k-state-active {
  /*active state styles - borders and backgrounds*/
  color: $color-text-primary !important;
  background-color: #eeeeee !important;
  text-transform: none;
  border-radius: 2px 0 0 0;
  border-top: none;
}

.k-tabstrip-items .k-loading {
  border-top: none !important;
  border-bottom-color: black !important;
  border-color: black !important;
}

.k-tabstrip-items .k-state-active .k-link {
  /*active state styles - text color*/
  color: $color-text-primary !important;
  text-transform: none;
}

.k-tabstrip:focus {
  box-shadow: none;
}

.imin-tabctrl {
  background-color: #fafafa !important;

  .k-tabstrip-items {
    margin-bottom: 0;
  }
}

.started-filter-text-color {
  color: $flow-icon-square-bg-color;
}

.due-soon-filter-text-color
  .due-today-filter-text-color
  .rejected-filter-text-color {
  color: $color-text-primary;
}

.overdue-filter-text-color {
  color: $color-redish;
}

.rejected-flows-filter-text-color {
  color: $color-redish;
}

.completed-filter-text-color {
  color: $color-action-green;
}

.flows-imin-dropdown-filter-align {
  padding-bottom: 6px !important;
}

.cancelled-flows-filter-text-color {
  color: $color-mandarin;
}
