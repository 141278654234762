.v2-feedback-commenthistory-container {
  margin-top: 20px;
  overflow-y: auto;
  max-height: calc(100% - 310px);
  margin-right: 30px;
}

.v2-feedback-commenthistory-comment-box {
  width: auto;
  margin: 0 30px 20px 40px;
  display: flex;
  align-items: center;
  position: relative;
}

.v2-feedback-commenthistory-avatar {
  margin: -5px 5px 0 0;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  line-height: 40px;
  width: 40px;
  height: 40px;
  font-size: 13px;
  font-weight: normal;
  cursor: pointer;
  border-radius: 50%;
  float: left;
  z-index: 10;
}

.v2-feedback-commenthistory-comment-content {
  background-color: #fff;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  margin: 2.5px 0 2.5px 30px;
  min-height: 60px;
  padding: 10px;
  width: 100%;
}

.v2-feedback-commenthistory-comment-header {
  padding-bottom: 10px;
  font-weight: bold;
}

.v2-feedback-commenthistory-comment-text {
  margin-bottom: 10px;
}

.v2-feedback-commenthistory-comment-files {
  display: flex;
  flex-wrap: wrap;
}

.v2-feedback-commenthistory-file-item {
  margin-right: 10px;
}

.v2-feedback-commenthistory-custom-divider {
  width: 1px;
  position: absolute;
  margin-left: 18px;
  background-color: #ccc;
}
