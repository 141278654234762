.process-map-v2-view-right-panel-header-container {
  display: flex;
  padding: 8px;
  flex: 1 1;
}

.process-map-v2-view-right-panel-header-row {
  flex: 1;
  font-weight: 600;
}

.process-map-v2-view-right-panel-header-label-cell {
  font-size: 12px;
}

.process-map-v2-view-right-panel-header-content-cell {
  font-size: 14px;
  flex: 0 0 50%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  margin-top: -5px;
}
