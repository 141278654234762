@import '../app.scss';
.flow-list {
  ul {
    list-style: none;
  }

  .flow-start-header {
    cursor: pointer;
  }
}

.flow-start-list-tabs {
  text-transform: none !important;
  background-color: #fafafa !important;
}

.flow-start-list-tabs-search {
  width: 50% !important;
}

.flow-start-list-filter-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: 13em;
  margin-top: 15px;
  margin-left: 6rem;
  margin-bottom: 1rem;
  white-space: nowrap;
  overflow: hidden;

  .fa-magnifying-glass {
    margin-right: -31px;
    display: inline-block !important;
    height: 32px !important;
    line-height: 20px !important;
    color: grey !important;
    margin-left: 8px;
  }

  .fa-times {
    position: absolute;
    right: 1rem;
    top: 25%;
    color: $color-text-primary;
    cursor: pointer;
  }

  .flow-start-search-input {
    width: 370px;
    margin-left: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .flow-start-search-container {
    position: relative;
    display: inline-block;
  }
}

.flow-start-list-filter-container .fa-times:hover {
  color: $blue !important;
}

.flow-start-search-input::placeholder {
  font-style: italic;
}

.flow-start-list-button-group {
  .k-button:hover {
    color: $color-text-primary !important;
  }
  margin-left: 70px !important;
  margin-bottom: 12px !important;
  .flow-start-list-tabs {
    text-transform: none;
    background: none;
    border: none;
    border-bottom: 1px solid transparent;
    border-radius: 0%;
    color: $color-text-primary !important;
    font-family: 'Open Sans', sans-serif;
    font-size: 15px !important;
    &.k-state-active {
      border-bottom-color: $color-primary-blue !important;
      border-width: 2px !important;
      background-color: #eeeeee !important;
    }
    &:hover {
      border-color: $color-tab-border-hover;
      border-bottom-width: 2px;
    }
  }
}
