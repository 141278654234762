$grayed: #a9a9a9;
$checkbox-checked-black: #35384d;
//$checkbox-checked-modeler-blue: #42A5F5;

.pmap-step-details-tab {
  display: flex;
  flex-direction: column;
  min-height: 300px;
  margin-top: 10%;

  .grayed {
    color: $grayed;
  }
  [type='checkbox']:checked + label:before {
    border-right: 2px solid $checkbox-checked-black !important;
    border-bottom: 2px solid $checkbox-checked-black !important;
  }
}
