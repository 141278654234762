@import '../../Flowingly.Shared.Angular/src/common.scss';

.fullname-wrapper {
  display: flex;
  align-items: center;
  gap: 5px;
}

.profile-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $color-white;
  text-align: center;
  text-transform: uppercase;
  width: 30px;
  height: 30px;
  font-size: 13px;
  font-weight: normal;
  border-radius: 50%;
}
