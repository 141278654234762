@import '../../../../app.scss';
div.v2-custom-tab-header {
  flex-grow: 1;
  min-height: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;

  .indicator {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0;
    background-color: $color-tab-border-hover;
  }

  &.is-hovered .indicator {
    height: 2px;
  }

  .label {
    text-transform: none;
    font-size: 15px;
  }
}
