@import './app.scss';

$templates-border-radius: 6px;
$color-template-pack-category: hsl(49, 100%, 65%);
$color-template-pack-template: hsl(98, 100%, 65%);
$color-template-pack: hsl(185, 100%, 65%);
$color-template-remove: hsl(0, 100%, 65%);
$color-template-add: rgba(255, 255, 255, 0.9);

#templates-library {
  display: flex;
  flex-grow: 1;
}

#templates-gallery {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(270px, 33%));
  overflow-y: auto;
  align-content: start;
  padding-left: 3%;
  padding-right: 15%;
  width: 82%;
  flex-grow: 1;
}

template-card {
  display: flex;
  flex-direction: column;

  .template-card {
    margin: 1.5em;
    padding: 12px;
    border: 1px solid #eee;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
      0 2px 10px 0 rgba(0, 0, 0, 0.12);
    border-radius: $templates-border-radius;
    cursor: pointer;
    overflow: clip;
    background-color: white;
    flex-grow: 1;

    &:hover {
      background-color: rgba($color: $color-primary-blue, $alpha: 0.05);
      border: 1px solid $color-primary-blue;
      font-weight: 600;
      padding: 6px;

      .template-card-content {
        padding: 18px;
      }
    }

    .template-card-content {
      padding: 12px;
      border-radius: $templates-border-radius;
      height: 100%;
      flex-direction: column;
      display: flex;

      .template-header {
        max-height: 4.5em;
        min-height: 2em;
        overflow-y: clip;
        font-weight: 600;
        font-size: 15px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 12px;

        .template-title {
          overflow-wrap: anywhere;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }

        i {
          margin-top: 12px;
          margin-left: 12px;
        }
      }

      .template-info {
        border-radius: $templates-border-radius;
        background-color: rgba(255, 255, 255, 0.9);
        padding: 8px;
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        .template-blurb {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 6;
          line-clamp: 6;
          -webkit-box-orient: vertical;
          flex-grow: 1;
          overflow-wrap: anywhere;
          font-weight: 400;
        }

        .template-learn-link {
          margin-top: 1em;
          color: $color-primary-blue;
        }
      }
    }
    .template-category-0 {
      background-color: $color-grape-lighter-50;
    }
    .template-category-1 {
      background-color: $color-mauve-lighter-50;
    }
    .template-category-2 {
      background-color: $color-redish-lighter-50;
    }
    .template-category-3 {
      background-color: $color-mandarin-lighter-50;
    }
    .template-category-4 {
      background-color: $color-mustard-lighter-50;
    }
    .template-category-5 {
      background-color: $color-forrest-lighter-50;
    }
    .template-category-6 {
      background-color: #b1d8d2; // Variation on brand colour Verde
    }
    .template-category-7 {
      background-color: #b8cee7; // Variation on brand colour Legacy Blue
    }
  }
}

#template-detail {
  align-items: center;
  height: calc(100vh - 130px);
  display: flex;
  flex-direction: column;

  #template-description-wrapper {
    overflow-y: auto;
    width: 100%;

    #template-description {
      min-width: 800px;
      width: 50%;
      margin: 0 auto;
      padding: 1em 2em 2em 2em;
      background-color: white;
      overflow-x: hidden;
    }
  }
}

#template-detail-buttons {
  display: flex;
  button {
    min-width: 13em;
  }
}

.template-pdf-content {
  padding: 20px 40px 50px 40px;
}

.tabbed-content {
  padding-left: 25px;
}

@media (max-width: 1450px) {
  #template-editor #template-card-wrapper {
    display: none;
  }
}

#template-editor {
  height: calc(100vh - 130px);

  #template-form-wrapper {
    overflow-y: auto;
    height: 100%;

    form {
      min-width: 800px;
      width: 50%;
      margin: 20px auto;
      padding: 1em 2em 2em 2em;
      background-color: white;
      overflow-x: hidden;
      overflow-y: clip;

      .k-editor .k-content {
        height: 500px;
      }
    }
  }

  #template-card-wrapper {
    position: fixed;
    top: 50px;
    right: 2%;
    width: 20%;

    h5 {
      margin: 1.5em;
    }
  }

  #template-uri {
    margin: 0px 1.5em;
    word-wrap: break-word;
  }
}

#templates-packs {
  #pack-menu {
    #pack-menu-header {
      margin-bottom: 20px;
    }
  }

  #pack-details {
    #pack-candidate-search {
      i {
        font-size: 2rem;
        margin-top: 8px;
      }
    }

    #pack-candidates {
      display: flex;
      justify-content: space-between;

      .pack-candidate-wrapper {
        width: 48%;
        height: 15em;
        display: flex;
        flex-direction: column;
      }
      .pack-candidate-list {
        width: 100%;
        flex-grow: 1;
        overflow-y: scroll;
        overflow-x: clip;
        padding: 10px 5px;
        border-radius: $templates-border-radius;
        display: flex;
        flex-direction: column;
      }

      #pack-candidates-categories {
        background-color: scale-color(
          $color: $color-template-pack-category,
          $lightness: 70%
        );
      }

      #pack-candidates-templates {
        background-color: scale-color(
          $color: $color-template-pack-template,
          $lightness: 70%
        );
      }
    }

    #pack-contents-wrapper {
      margin-top: 20px;

      i {
        width: 1.5em;
        margin-top: 3px;
        flex-shrink: 0;
      }

      #pack-contents {
        .pack-category {
          .pack-category-title {
            display: flex;

            .pack-category-name {
              background-color: $color-template-pack-category;

              &:hover {
                background-color: $color-template-remove;
              }
            }

            .pack-category-switch {
              background-color: #ddd;
              margin-left: 10px;
              cursor: pointer;
              flex-shrink: 0;

              &:hover {
                background-color: white;
              }
            }
          }

          .pack-category-templates {
            padding: 5px 0px;
            display: flex;
            gap: 10px;
            flex-wrap: wrap;

            .pack-category-template {
              background-color: scale-color(
                $color: $color-template-pack-template,
                $lightness: 70%
              );
              border-radius: $templates-border-radius;
              padding: 2px 6px;
              display: flex;
              overflow-wrap: anywhere;
              border: 1px solid #ccc;
            }
          }
        }

        .pack-template {
          background-color: $color-template-pack-template;
        }

        .pack-list-item {
          align-self: flex-start;
          margin-bottom: 6px;
          box-shadow: 3px 3px 3px #ccc;
          cursor: pointer;
          border-radius: $templates-border-radius;
          padding: 2px 6px;
          display: flex;
          overflow-wrap: anywhere;

          &:hover {
            background-color: $color-template-remove;
          }
        }
      }
    }

    .pack-candidate {
      cursor: pointer;
      border-radius: $templates-border-radius;
      padding: 2px 6px;

      &:hover {
        background-color: $color-template-add;
      }

      .pack-candidate-line {
        display: flex;
        overflow-x: clip;
      }

      i {
        width: 1.5em;
        margin-top: 3px;
        flex-shrink: 0;
      }
    }
  }
}

.templates-page {
  display: flex;
  height: calc(100vh - 135px);
  margin-top: 20px;

  &.templates-admin-page {
    height: calc(100vh - 185px);
  }

  h4 {
    i {
      margin-right: 10px;
    }
  }

  .menu {
    padding: 0px 10px 15px 13px;
    margin-left: 15px;
    border-right: 1px solid #ddd;
    width: 15%;
    min-width: 200px;
    display: flex;
    flex-direction: column;

    .search {
      display: flex;

      i {
        font-size: 26px;
        line-height: 36px;
        margin-right: 5px;
      }
    }

    .menu-list {
      display: flex;
      flex-direction: column;
      overflow-y: auto;

      .changed {
        margin-right: 5px;
        margin-top: 3px;
        color: red;
      }

      .menu-list-item {
        padding: 5px 0px 5px 0px;
        cursor: pointer;
        display: flex;
        overflow-wrap: anywhere;

        &:hover {
          color: $color-primary-blue;
        }

        &:first-of-type {
          padding-bottom: 20px;
        }

        .category-count {
          margin-left: 10px;
          border-radius: 10px;
          padding: 0px 5px;
          height: 19px;
        }
      }

      .selected {
        font-weight: 600;
      }
    }
  }

  .selection-details {
    background-color: white;
    padding: 0px 50px 40px 30px;
    margin-left: 5%;
    flex-grow: 1;
    max-width: 1000px;
    width: 90%;
    min-width: 500px;
    overflow-y: auto;

    > div {
      margin-top: 20px;
    }
    h2 {
      margin-bottom: 40px;
      overflow-wrap: anywhere;
      line-height: 34px;
    }

    .content-list {
      border-radius: $templates-border-radius;
      background-color: #eee;
      overflow-y: auto;
      padding: 10px;
      flex-grow: 1;
      min-height: 4em;
      display: flex;
      flex-direction: column;

      .empty-list-message {
        color: grey;
        font-style: italic;
        margin: auto;
        padding: 0px 10%;
      }
    }
  }
}

#templates-tenants {
  #tenant-details {
    h2 {
      i {
        margin-top: -5px;
        margin-right: 10px;
        color: red;
      }
    }

    #tenant-pack-selection {
      display: flex;
      justify-content: space-between;

      h4 {
        i {
          margin-right: 10px;
        }
      }

      .packs-wrapper {
        width: 48%;
        height: 25em;
        display: flex;
        flex-direction: column;

        .content-list {
          .pack {
            background-color: $color-template-pack;
            align-self: flex-start;
            margin-bottom: 6px;
            box-shadow: 3px 3px 3px #ccc;
            cursor: pointer;
            border-radius: $templates-border-radius;
            padding: 2px 9px 2px 6px;
            display: flex;
            overflow-wrap: anywhere;

            &:hover {
              background-color: $color-template-add;
            }

            &.selected-pack {
              &:hover {
                background-color: $color-template-remove;
              }
            }

            i {
              width: 1.5em;
              margin-top: 3px;
              flex-shrink: 0;
            }
          }
        }
      }
    }

    #tenant-buttons {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row-reverse;
    }
  }

  #tenants-list {
    .enabled-tenant-icon {
      color: green;
      margin-top: -2px;
      margin-left: 5px;
    }
  }
}

#import-template-list {
  max-height: 70vh;
  overflow-y: auto;
}

.template-import-checkbox {
  align-self: center;
  padding-top: 10px;
}
