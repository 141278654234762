#maintenance-application-settings {
  .global-actions {
    display: flex;
    justify-content: space-between;
  }

  .input-row {
    display: flex;
    flex-direction: row;
    column-gap: 4em;
    margin-top: 20px;
    margin-bottom: 40px;
  }

  #setting-name {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  #setting-value {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  #setting-type {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    select {
      display: block;
    }
  }

  #setting-action {
    flex-grow: 2;
    margin-top: 18px;
  }

  #setting-delete {
    flex-grow: 1;
    margin-top: 18px;
  }

  #setting-comments {
    display: flex;
    flex-direction: column;
    flex-grow: 12;
    flex-basis: 0;
    textarea {
      height: 6em;
    }
  }

  #setting-overrides {
    flex-basis: 50%;
  }

  #setting-tags {
    display: flex;
    flex-direction: column;
    flex-grow: 12;
    flex-basis: 0;
    select {
      display: block;
    }
  }

  .tag-list {
    display: flex;
    flex-wrap: wrap;
  }

  .tag {
    margin: 5px;
    padding: 5px 10px;
    border-radius: 3px;
    background-color: #eee;
    max-width: 20em;
    overflow-x: auto;

    &.removable {
      cursor: pointer;
      box-shadow: 2px 2px 4px #ccc;

      &:hover {
        background-color: hsl(0, 100%, 65%);
      }
    }

    &.add {
      cursor: pointer;
      box-shadow: 2px 2px 4px #ccc;

      &:hover {
        background-color: hsl(120, 100%, 65%);
      }
    }

    &.restorable {
      cursor: pointer;

      border: 2px dashed darkgray;

      &:hover {
        background-color: hsl(120, 100%, 65%);
      }
    }

    &.highlight {
      background-color: hsl(27, 100%, 85%);
    }
  }

  .settings {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;

    .settings-header-short {
      width: 8em;
    }

    #settings-tags-header {
      display: flex;
      div {
        margin-top: 18px;
        margin-right: 10px;
      }
      input {
        margin-top: 7px;
      }
    }

    .setting {
      border-bottom: 1px solid gray;
      cursor: pointer;

      &:hover {
        background-color: lightgray;
      }

      .setting-value {
        overflow-wrap: break-word;
      }
    }
  }

  textarea {
    background-color: white;
  }
}
