@import '../../app.scss';
.create-database__title {
  padding-left: 15px;
  padding-top: 50px;
  overflow-wrap: break-word;
  max-width: $max-width-contents;

  &.isMobile {
    padding-left: 10px;
  }
}

.create-database__form {
  padding: 15px 25px;

  .k-textbox {
    width: 200px !important;
  }
}

.create-database__label {
  margin-left: 0.65rem;
}

.create-database__grid,
.edit-database__grid {
  .k-widget {
    background-color: #fafafa;
  }

  .k-header.k-grid-toolbar {
    background-color: #fafafa;
    border-color: #fafafa;
  }

  .k-grid-add {
    background-color: $color-primary-blue !important;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.26);
    border: none;
    text-transform: capitalize;

    .k-i-plus {
      display: none;
    }
  }
  // the positioning and relativiely of the elements are fucked
  // so we must base it relative to the viewport instead
  .k-grid-content {
    max-height: calc(100vh - 280px);
  }
}

.create-database__error-message {
  color: red;
  padding-bottom: 10px;
}

.editDatabaseToolbar {
  box-shadow: none;
  width: 60px;
  display: inline-block;

  .dropdown-button {
    padding: 0 10px 10px 10px;
    font-size: 28px;
  }

  ul {
    width: 118px;
    right: 18px;
    top: 38px !important;
    background-color: white;

    li {
      background-color: white;

      :hover {
        background-color: #eee;
      }
    }
  }
}
.k-grid-add[disabled] {
  background-color: #d3d3d3!important;
  color: #999!important;
  cursor: not-allowed;
}

