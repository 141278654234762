@import '../../../app.scss';

.categoriesv2-modal-edit-button-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 20px;

  button {
    padding: 7px 28px;
  }

  .categoriesv2-modal-cancel-button {
    text-transform: capitalize;
    color: #888;
    background-color: transparent;
    margin-top: 2px;
    padding: 0px 15px;
    font-family: 'Open Sans', sans-serif;
    &:hover {
      background-color: transparent;
      color: $blue;
    }
  }

  button.footer-action-success-button {
    text-transform: none;
    color: #ffffff;
    background-color: $color-action-green;
    border-radius: 0.5rem;
    font-family: 'Open Sans', sans-serif;

    &:hover {
      background-color: $color-action-green;
      box-shadow: none;
    }

    box-shadow: none;
  }

  button.footer-action-danger-button {
    text-transform: none;
    color: #ffffff;
    background-color: #f44336;
    border-radius: 0.5rem;
    font-family: 'Open Sans', sans-serif;

    &:hover {
      background-color: #f44336;
      box-shadow: none;
    }

    box-shadow: none;
  }
}

div .categoriesv2-modal-textfield {
  display: flex;
}

.categoriesv2-modal-textfield input[type='text'] {
  margin: 0;
}
