.flow-title-publicmap {
  overflow-wrap: break-word;
  z-index: 999;
  display: inline-block;
  max-width: 29vw;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  height: 26px;
  padding-left: 15px;

  &.isMobile {
    font-size: 15px;
    position: fixed;
    max-width: 46vw;
    padding: 0px;
    margin: 0px;
    left: 60px;
    top: 12px;
  }
  //iPad and iPadPro - portrait
  @media screen and (width: 1024px) and (min-height: 768px) and (max-height: 1366px) {
    &.isMobile {
      max-width: 34vw;
    }
  }
  //iPad - landscape
  @media screen and (width: 768px) and (height: 1024px) {
    &.isMobile {
      max-width: 24vw;
    }
  }
}
