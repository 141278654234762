$sliderColor: #42a5f5;

.process-map-v2-view-left-panel-process-map-slider-container {
  background-color: #eee;
}
.process-map-v2-view-left-panel-process-map-slider {
  .MuiSlider-track {
    background-color: $sliderColor;
    border-color: $sliderColor;
  }

  .MuiSlider-rail {
    background-color: $sliderColor;
  }
  .MuiSlider-thumb {
    background-color: $sliderColor;
    z-index: 50;
  }
}

button.process-map-v2-share-button {
  text-transform: none;
  color: #ffffff;
  background-color: #4caf50;
  border-radius: 0.5rem;
  font-family: 'Open Sans', sans-serif;
  margin-left: 10px;

  &:hover {
    background-color: #4caf50;
    box-shadow: none;
  }

  box-shadow: none;

  .process-map-v2-share-icon {
    margin-right: 10px;
  }
}
