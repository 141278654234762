.roles-action-wrapper {
  position: relative;
}

.list-actions__action {
  &.list-actions-disabled {
    cursor: default;
    color: #d4d4d4;
  }

  &.list-actions-disabled i:hover {
    color: #d4d4d4;
  }
}

.role-dialog {
  overflow-y: clip;

  #permissions-choice-list {
    max-height: 54vh;
    overflow-y: auto;
    padding-top: 10px;

    [type='checkbox'] + label {
      line-height: 22px;
      padding-left: 30px;
    }

    .permissions-list-item {
      padding: 6px 2px 4px 8px;
      display: flex;
      margin-left: 30px;
      .permission-description {
        padding-top: 2px;
      }

      .fa-lock {
        margin-top: -3px;
        padding-right: 5px;
      }
    }

    label {
      font-weight: 600;
    }

    .permissions-group {
      margin-bottom: 5px;

      .permissions-group-header {
        padding: 4px 0px 2px 6px;
        background-color: hsl(221, 100%, 95%);
      }

      .permissions-list-item {
        border-left: 1px solid #eee;
      }
    }

    .permissions-sub-group {
      margin-left: 30px;

      .permissions-sub-group-header {
        padding: 4px 0px 2px 6px;
        background-color: hsl(221, 0%, 95%);
      }

      .permissions-list-item {
        border-left: 1px solid #eee;
      }
    }
  }
}
