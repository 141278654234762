.ngdialog-content {
  .card {
    .content {
      .input-field {
        h5 {
          font-weight: bold;
        }
      }
    }
  }
}

.category-dialog {
  .icon {
    font-size: 26px;
    height: 26px;
  }
}

.select-approver-container {
  display: flex;
  align-items: center;
  width: 100%;
  .select-approver {
    flex-grow: 1;
    width: 100%;
  }
}
