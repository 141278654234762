@import '../../app.scss';
.flow-history-completed {
  background-color: #fff;
  min-height: $history-block-height;
  line-height: $history-block-height;
  padding-left: $block-header-indent;
  padding-top: calc($history-block-height / 10);
  padding-bottom: 10px;

  .text {
    margin-left: -10px;
  }
}

.flow-history-completed.isMobile {
  padding-left: 45px;

  .text {
    line-height: 16px;
    padding-top: 10px;
    padding-bottom: 2px;
    margin-right: 5px;
  }

  .avatar {
    left: 30px;
  }
}
