.categoriesv2-delete-modal-legend {
  color: #35384d;
  padding: 3px 6px;
}

.categoriesv2-delete-modal-fieldset {
  border: 1px solid silver;
}

.categoriesv2-delete-modal-warning-icon {
  font-size: 13px !important;
  height: 13px !important;
  color: orangered;
}

.categoriesv2-delete-modal-fieldset-dropdown {
  border: 1px solid silver;
  height: 40px;
  padding: 5px;
  margin-top: 43px;
  .k-input-value-text {
    font-size: 13px;
    font-family: 'Open Sans', sans-serif !important;
  }
  .k-picker-solid {
    background-color: white !important;
    border-bottom: none !important;
  }
  .k-button {
    background-color: white !important;
  }
  .k-input-inner {
    padding-block: 0 !important;
  }
}

.categoriesv2-delete-modal-textfield input[type='text'] {
  margin: 0;
}

.categoriesv2-delete-modal-button-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 20px;
}

.categoriesv2-delete-modal-content-container {
  display: flex;
}

.categoriesv2-delete-modal-inner-content-container {
  width: 50%;
  display: inline-block;
  vertical-align: top;
}

.categoriesv2-delete-modal-outer-content-container {
  padding: 5px;
}

.categoriesv2-delete-modal-inner-content-container-text {
  font-family: 'Open Sans', sans-serif !important;
  font-size: 13px;
  line-height: 20px;
}

.categoriesv2-delete-modal-last-content-container {
  width: min-content;
}

.v2-categories-row td {
  border-bottom: none;
}

.v2-categories-row th {
  border-bottom: none;
}

.k-animation-container-shown {
  z-index: 1500 !important;
}

.k-dropdowntree-popup {
  max-height: 290px;
  overflow-y: auto;
  border: 1px solid #ccc;
}

.k-icon.k-svg-icon.k-svg-i-caret-alt-down {
  width: 24px;
  height: 24px;
}
