.user-condition__contents {
  max-height: 600px;
  overflow: auto;
}

.terms-conditions-dialog {
  .ngdialog-content {
    min-width: 90% !important;
  }
}
