.v2-view-feedback-comments-container {
  border: 1px solid #ddd;
  height: calc(100vh - 210px);
  overflow-y: auto;
}
.v2-view-feedback-add-comment-container {
  position: relative;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 0 30px;
}

.v2-view-feedback-add-comment-container-inner {
  display: block;
  background-color: #fff;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  padding: 10px;
  margin-top: 20px;

  &:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: ' ';
    clear: both;
    height: 0;
  }
}

.v2-view-feedback-add-comment-header {
  font-weight: 600;
  padding-bottom: 5px;
}
.v2-view-feedback-add-comment-action {
  display: flex;
  align-items: center;

  .v2-view-feedback-add-comment-foot-text {
    flex: 1 1 auto;

    .v2-view-feedback-add-comment-attach-file {
      display: flex;

      label {
        cursor: pointer;
        flex: 0 0 auto;
        margin-right: 5px;
        line-height: 34px;
      }

      ul {
        margin: 0;

        li {
          margin: 3px 0 3px 5px;
        }
      }
    }
  }
}
.file-upload-field .file .fa-xmark {
  cursor: pointer;
  color: #2196f3;
  float: right;
  line-height: 20px;
}

.mentions__mention {
  color: #2196f3;
}
