@import '../../../../app.scss';

.feature {
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.8em;
  margin-bottom: 0.75em;
  border-bottom: 1px solid $color-grey-lighter-80;

  a.feature-name {
    cursor: pointer;
  }

  .feature-details {
    flex: 1;
  }

  .feature-value {
    margin-top: 0.3em;
    width: 120px;
    margin-left: 4em;
  }
}
