@import '../app.scss';
.timeline-image {
  display: block;
  background: white;
  border-radius: 2px;
  margin: 5px 15px;
  margin-left: 0;
  padding: 2px 5px;

  a {
    cursor: pointer;
  }

  &.filename-only {
    background: transparent;
  }

  .fileWrapper {
    line-height: 30px;
    margin-left: 5px;
  }

  .fake-image {
    span {
      color: #eee !important;
    }

    span:first-child {
      background: #eee;
      height: 15px;
      width: 15px !important;
      padding-top: 11px;
      display: inline-block !important;
      margin-bottom: -2px;
    }
  }
}

.timeline .card .alert {
  margin-top: 0;
}

/* styles for the flow icon square directive */
flow-icon-square {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60px;
  min-width: 60px;
  background-color: $flow-icon-square-bg-color;
  color: white;
  font-weight: 600;
  font-size: 17px;
}

$avatar-size: 35px;
$avatar-size-small: 20px;

$media-height: 67px;
/* mobile card view - these styles are for the mobile card view, used when the runner is on small devices.*/
.runner-flow-list .collapsible-header {
  width: 100%;
}

.media {
}

.media,
.bd {
  overflow: hidden;
  _overflow: visible;
  zoom: 1;
  position: relative;
  min-height: $media-height;
}

.media .img {
  float: left;
  margin-right: 10px;
}

.media .img img {
  display: block;
}

.media .imgExt {
  float: right;
  margin-left: 10px;
}

.media-card {
  width: 100%;
}

.media flow-avatar-circle img {
  height: $media-height;
  width: $media-height;
  line-height: $media-height;
  border-radius: unset;
  margin: 0;
}

.text-underline {
  text-decoration: underline;
}

.media h5 {
  font-weight: 800;
}

.media h6 {
  color: #888;
}

.media .status {
  position: absolute;
  top: 5px;
  right: 5px;
}

.media .who {
  position: absolute;
  bottom: 0px;
  right: 5px;
}

.media figcaption {
  padding-left: 5px;
}

/* runner-flow-list */
.ml-n15 {
  margin-left: -15px;
}

.ml-24 {
  margin-left: 24px !important;
}

.mr-24 {
  margin-right: 24px !important;
}

.pl-35 {
  padding-left: 38px !important;
}

.pr-35 {
  padding-right: 38px !important;
}

.pl-1em {
  padding-left: 1em !important;
}

.pr-1em {
  padding-right: 1em !important;
}

.text-black {
  color: black;
}

flow-timeline-filelist {
  margin-bottom: 10px; //this is so that the relative time background does not overlap the white filebox
  display: block;

  span {
    display: inline !important;
  }
}

.timeline {
  margin: 0 15px 0 15px;

  flow-avatar-circle {
    position: absolute;
    left: 10px;
    top: 5px;
  }

  &:before {
    content: none;
  }
}

.timeline-block {
  color: #888;
  /* ensure that our rows and columns work as expected*/
  .row,
  .col {
    padding-bottom: 0;
  }

  .row {
    margin-top: 0;
  }

  .card .title {
    background-color: #efefef;
    padding: 0px 5px 16px 5px;
    //padding-bottom: 16px;
  }

  .card .content {
    h6 {
      padding-bottom: 3px;
      border-bottom: 1px solid #eee;
      margin-bottom: 10px;
    }
  }
}

.runner-flow-list {
  li:last-of-type {
    margin-bottom: 35px;

    .card {
      margin-bottom: 25px !important;
    }
  }

  .collapsible-header {
    height: 39px;
    min-height: initial;
    //padding: 0;
  }

  .collapsible.popout > li {
    margin-bottom: 9px;
  }
}

.runner-flow-list-grid {
  font-weight: bold;
  overflow: hidden;
  padding: 0 10px;

  h6 {
    padding: 3px 4px;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%; //required for sllipsis to work
  }

  flow-avatar-circle {
    margin-top: 2px;
    display: block;
    height: 40px;
  }

  .text-bordered {
    border: 1px solid #ccc;
    border-radius: 3px;
  }

  .checkbox-small {
    display: inline;
    color: #999;

    label {
      font-size: 11px;
      margin-left: 5px;
      padding-left: 15px;

      &:before {
        margin-top: 5px;
        width: 13px;
        height: 13px;
        border: 2px solid #999;
      }
    }
  }

  .fa-paperclip {
    position: absolute;
    right: -28px;
    top: -48px;
  }

  [class*='col-'] {
    float: left;
    padding-right: 20px;
    /*background: white;
        border-left: 1px solid grey;*/
  }

  [class*='col-']:last-of-type {
    padding-right: 0;
  }

  .col-a {
    width: auto;
  }

  .col-5 {
    width: 5%;
  }

  .col-10 {
    width: 10%;
  }

  .col-15 {
    width: 15%;
  }

  .col-15 {
    width: 15%;
  }

  .col-20 {
    width: 20%;
  }

  .col-25 {
    width: 25%;
  }

  .col-30 {
    width: 30%;
  }

  .col-35 {
    width: 35%;
  }

  .col-45 {
    width: 45%;
  }

  .col-50 {
    width: 50%;
  }
}

.runner-flow-list-grid:after {
  content: '';
  display: table;
  clear: both;
}

.collapsible-header.active {
  margin-top: 15px;
}

.removeAvatarDiv a:hover {
  color: #2196f3 !important;
}

/* styles for the page header directive */
.runner-page-header-wrapper {
  margin-top: 40px;
}

.page-header-component {
  margin-right: 8px;
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  overflow: hidden;
  align-items: baseline;

  h4 {
    margin-left: 25px;
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 10px;

    i {
      line-height: 25px;
      font-size: 26px;
    }
  }
}

@media (max-width: 600px) {
  /* all columns full width */
  .collapsible-header {
    content: '';
    display: table;
    clear: both;
  }

  .runner-flow-list-grid {
    [class*='col-'] {
      width: 100%;
    }
  }
}

@media (min-width: 768px) {
  .runner-flow-list {
    .runner-flow-list-grid {
      .avatar {
        width: $avatar-size;
        height: $avatar-size;
        font-size: 13px;
        line-height: $avatar-size;
        margin-top: 10px;
      }
    }

    .collapsible-header {
      padding: 0 1rem;
    }
  }

  .runner-flow-list-grid {
    padding: 0;
  }
}
