@import '../app.scss';
.flow-hidden-diagram {
  visibility: hidden;
}
/* HELPERS /////////////////////////////////////////////////// */
.fw500 {
  font-weight: 500;
}

.bold {
  font-weight: bold;
}

.fgreen {
  color: $green;
}

.fblue {
  color: $blue;
}

.fpink {
  color: $pink;
}

.fgrey {
  color: $grey;
}

.left {
  float: left;
}

.right {
  float: right;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin truncate() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bottom-border:after {
  /* parent must be relative */
  content: '';
  border-bottom: #ddd;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 50%;
  width: 1px;
}

.right-bottom {
  position: absolute;
  right: 0px;
  bottom: 0px;
}

.chip {
  line-height: 18px;
  font-size: 9px;
  text-align: center;
  border-radius: 9px;
  opacity: 1;
  background: #f0f8ff 0% 0% no-repeat padding-box;
  display: inline-block;
  padding-right: 10px;
  padding-left: 10px;
}

.blue-chip {
  color: #0d47a1;
  background-color: #e3f2fd;
}

.green-chip {
  color: #1b5e20;
  background-color: #e8f5e9;
}

.grey-chip {
  color: #474747;
  background-color: #eeeeee;
}

.flow-model {
  width: 100%;
  max-height: 500px;
  background: white;
}

.flow-model canvas {
  outline: none;
  max-height: 500px;
}

/* FLOW NEW DESIGNS FLOW-1616, FLOW-1618 ///////////////////////////////////////////////////*/
.flow-block {
  margin-bottom: 20px;

  &.isMobile {
    margin-left: 20px;
    margin-right: 5px;
  }
}

.flow-block__header {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  margin-left: 25px;
  margin-bottom: 1px;
  word-break: break-all;

  &.isMobile {
    margin-left: 0;
    margin-bottom: 8px;
  }
}

.flow-list__column-title {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 20px;
  margin: 0px 10px;
}

.flow-list__column-title__flow-icon-square {
  width: 6%;
}

.flow-list__column-title__contents__subject {
  width: 36.5%;
  margin-left: 1%;
}

.flow-list__column-title__contents__description {
  width: 62%;
}

.flow-list__column-title__contents {
  width: 94%;
}

.flow-list__contents {
  width: 100%;
}

.flow-list__contents__description {
  margin-right: 2%;
  width: 48%;
}

.flow-list__column-title__contents__current-step,
.flow-list__contents__current-step {
  margin-right: 2%;
  width: 29%;
}

.flow-list__contents__flow-status {
  margin: 0 2%;
  width: 12%;
}

.flow-list__contents__subject {
  margin-right: 2%;
  width: 34%;
}

.flow-list__contents__process-owner {
  margin-right: 2%;
  width: 34%;
}

.flow-list__contents__workflow-type__block {
  margin-right: 15px;
}

.flow-list__contents__workflow-type__header {
}

.flow-list__contents__workflow-type__text {
  width: 122px;
  margin-right: 5px;
}

.flow-list__contents__workflow-type {
  &__confidential {
    @extend .chip;
    @extend .blue-chip;
  }

  &__normal {
    @extend .chip;
    @extend .green-chip;
  }
}

.flow-list__contents__due-date {
  width: 14%;
  margin-right: 15px;
}

.flow-finalized__contents__due-date {
  width: 46%;
}

.flow-list__contents__file-attachment-icon {
  width: 2%;
}

.flow-list__contents__workflow-type__header,
.flow-list__contents__workflow-type__text--mobile,
.flow-list__contents__workflow-type__header--mobile,
.flow-list__contents__process-owner__text,
.flow-list__contents__flow-status__identifier,
.flow-list__contents__flow-name__text,
.flow-list__contents__subject__text,
.flow-list__contents__description,
.flow-list__contents__current-step__text,
.flow-list__contents__flow-status__text,
.flow-list__contents__due-date__text,
.flow-header__extra__header,
.flow-header__extra--start-flow {
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  margin: 15px 0 5px 0;
  text-align: left;
  word-break: break-word;
}

.flow-list__contents__subject__text2 {
  padding-right: 5px;
  overflow-wrap: anywhere;
}

.flow-current-step__contents__due-date__text {
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  text-align: left;
}

.flow-list__contents__flow-status__progress {
  margin-top: 10px;
}

.flow-list__contents__description,
.flow-list__contents__flow-name__text {
  margin-bottom: 15px;
}

.flow-status-progress--container {
  background-color: rgba(213, 230, 243, 1);
  border-radius: $progressbar-radius;
  width: 100%;
  margin-bottom: 15px;
}

.flow-status-progress--bar {
  background-color: #42a5f5;
  border-radius: $progressbar-radius;
  height: $progressbar-height;
}

.flow-status-progress--text {
  margin-top: 5px;
}

.flow-list__contents__subject__created-by,
.flow-list__contents__subject__started-by,
.flow-status-progress--text,
.flow-list__contents__current-step__flowed-to,
.flow-list__contents__due-date__due-by {
  font-weight: 600;
  font-size: 12px;
  color: $color-flow-content-secondary;
  line-height: 16px;
  margin-bottom: 15px;
  word-wrap: break-word;
}

/* Mobile View */
.flow-header__main {
  border-bottom: 1px solid #ddd;
}

.flow-header__extra {
  margin: 20px 20px 0 20px;
}

.flow-header__extra--start-flow {
  margin-bottom: 20px;
}

.flow-list__contents__header--mobile {
  margin: 0 10px 0 20px;
  width: 100%;
}

.flow-list__contents__subject--mobile {
  margin: 0 10px 0 20px;
}

.flow-header__extra__header {
  width: 100px;
  min-width: 100px;
  margin: 0;
}

.flow-list__contents__flow-name__text--mobile {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 10px;
  line-height: initial;
}

.flow-list__contents__current-step__text--mobile {
  margin-top: 25px;
  margin-bottom: 5px;
}

.flow-list__contents__flow-status__text--mobile {
  margin-top: 0;
  margin-bottom: 5px;
}

.flow-list__contents__subject__started-by--mobile {
  margin-top: 15px;
}

.flow-list__contents__current-step__flowed-to--mobile {
  margin-top: 15px;
  margin-bottom: 15px;
}

.flow-list__contents__due-date__text--mobile {
  margin-top: 0;
}

.flow-list__contents__due--mobile {
  width: 50%;
}

.flow-list__contents__workflow-type__header--mobile {
  margin-top: 0;
}

.flow-list__contents__flow-status--mobile {
  padding-top: 6px;
  width: 100%;
}

.flow-list__contents__due__type--mobile {
  margin-top: 30px;
  padding-bottom: 10px;
}

.flow-list__contents__workflow-type__text--mobile {
  margin-top: 0;
  margin-bottom: 10px;
  line-height: initial;
}

.expanded {
  .k-item {
    width: 33.2% !important;
    text-align: center;
    border: 0px solid $block-border-color !important;
    font-weight: 600;
    cursor: pointer !important;
    background-color: white !important;

    &.mobile {
      width: 50% !important;
    }
  }

  .k-item.k-state-active {
    border-bottom-color: #00b0ff !important;
    border-bottom-width: 2px !important;
  }

  .k-content {
    padding: 0px !important;
    border: 0px;
  }
}

.k-tabstrip {
  background-color: #fff;
}

.flow-current-steps-container__form-wrapper {
  border: 1px solid $block-border-color;
}

.flow-footer-wrapper {
  visibility: visible;
  opacity: 1;
}

.flow-footer-wrapper--hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
}

.flow-column-title__flow-icon-square---todo--imin {
  width: 3.5%;
}

.flow-column-title__contents--todo--imin {
  width: 96.5%;

  .flow-column-title__flow-name--todo--imin {
    width: 12%;
    margin-left: 0.5%;
  }

  .flow-column-title__subject--todo--imin {
    width: 25%;
  }

  .flow-column-title__due-date--todo--imin {
    width: 9.5%;
  }

  .flow-column-title__current-step--todo--imin {
    width: 53%;
    text-align: center;
  }
}

.flow-todo-imin-content {
  margin: 0 10px 0px 15px;

  .flow-todo-imin-halfpane {
    width: 50%;

    .flow-todo-imin-history-container {
      .k-tabstrip > .k-content {
        padding: 0px;
      }

      .k-content .k-state-active {
        height: 100vh;
        overflow-y: auto;
      }
    }
  }
}

.pane-left {
  .flow_contents__flow-status--todo--imin {
    width: 24%;
    margin-left: 1.5%;
  }

  .flow_contents__subject--todo--imin {
    width: 50%;
    margin-right: 2.5%;
  }
}

.pane-right {
  overflow-y: auto;
}

#tabstrip {
  ul.k-tabstrip-items {
    margin-bottom: 0px;
  }
}

.k-tabstrip-top > .k-tabstrip-items .k-item {
  margin-right: 0px;
}
