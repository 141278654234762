.v2-feedback-mentions-mention {
  position: relative;
  pointer-events: none;
  background-color: rgb(208 231 245);
}
.v2-feedback-avatar-container {
  position: relative;

  .v2-feedback-avatar {
    padding: 4px;
    width: auto;
  }
}
.v2-feedback-avatar-icon {
  margin: -5px 5px 0 0;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  line-height: 30px;
  width: 30px;
  height: 30px;
  font-size: 13px;
  font-weight: normal;
  cursor: pointer;
  border-radius: 50%;
  float: left;
}
.v2-feedback-mentions-input__highlighter {
  padding: 9px;
  border: 1px solid transparent;
  height: 100px;
}

.v2-feedback-mentions-input__input {
  padding: 9px;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  overflow-y: auto !important; // overriding the third party library inline styling
}

.v2-feedback-mentions-input__suggestions__list {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 14px;
  overflow-y: scroll;
  max-height: 130px;
  border-radius: 3px 0 0 3px;
}

.v2-feedback-mentions-input__suggestions__item {
  padding: 12px 15px;
  height: 55px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  &--focused {
    background-color: #cee4e5;
  }
}
