@media only screen and (max-width: 700px) {
  #SectionText {
    width: calc(100%);
    height: auto;
  }

  #SectionChart {
    width: calc(100%);
    height: inherit;
  }

  #SectionReport {
    height: inherit;
  }
}

@media only screen and (min-width: 701px) {
  #SectionText,
  #SectionChart {
    width: 100%;
    height: 100%;
    /*
        width: calc(100% - 22px);
        height: calc(100% - 22px);
 */
  }

  #SectionReport {
    height: 50%;
  }
}

#JavascriptApiMenu {
  height: 150px;
  margin-left: 10px;
  margin-top: 35px;
}

#ExagoWrapper,
#ExagoWrapper-Persistent {
  height: calc(100% - 115px);
  top: 106px;
  position: absolute;
  width: calc(100% - 90px);
}

#ExagoWrapper-Persistent {
  right: 17px;
}

#Container-Report,
#Container-FullUI {
  height: calc(100%);
  position: relative;
  margin-left: 5px;
}

#SectionText,
#SectionChart,
#SectionReport {
  float: left;
  position: relative;
  padding: 10px;
}

#SectionText {
  border: 1px solid gray;
  overflow-y: auto;
}

#SectionReport {
  width: calc(100%);
}

.hide {
  display: none;
}
