.empty-state {
  margin-top: 5%;
}

.empty-state__summary {
  font-weight: 600;
}

.empty-state__text {
  margin-top: 30px;
  line-height: 30px;
}
