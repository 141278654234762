.instruction-field-wrapper--history {
  background-color: transparent;
  margin-top: 15px;
}

.instruction-field--history {
  background-color: transparent !important;
}

.instruction-field.instruction-field--history {
  div {
    word-wrap: break-word;
    margin-right: 5px;
  }

  div.isMobile {
    word-break: break-all;
  }
}
