.maintenance {
  #business {
    display: flex;
    padding: 0px 0px 15px 25px;
    kendo-combo-box-facade {
      max-width: 40em;
      display: block;
    }
    #business-select {
      flex-grow: 1;
      min-width: 30em;
    }
    h1 {
      flex-grow: 2;
      overflow-wrap: anywhere;
    }
  }

  .maintenance-flow {
    margin: 0px 25px 0px 25px;
    label {
      font-weight: 600;
    }
    .schema-actions {
      margin-top: 30px;
    }
  }
}
