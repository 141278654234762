@import '../../../../app.scss';
$bgColor: $default-background-color;
.v2-categories-custom-pager {
  position: sticky !important;
  bottom: -1px !important;
  background-color: $bgColor !important;
  z-index: 1 !important;
  width: 100% !important;
}
.k-pager {
  border-style: none !important;
}

.k-button-icon {
  width: 2.5rem !important;
}

.k-pager-numbers .k-selected {
  border-color: #3f51b5 transparent transparent !important;
  border-radius: 0 !important;
  color: transparent !important;
}

.k-button-text {
  color: #444;
}

.k-pager-wrap .k-pager-numbers .k-state-selected {
  margin-top: -0.45em;
  padding-top: 0.45em;
  min-width: 2.429em;
}
.k-selected .k-button-text {
  color: #3f51b5 !important;
}

.k-button.k-state-hover,
.k-button:hover {
  color: $bgColor !important;
}

.k-button::before {
  transition: none !important;
}

.k-pager-nav:active {
  background-color: $bgColor !important;
}

.k-pager-nav:focus {
  color: $bgColor !important;
}
