.ngdialog {
  &.ngdialog-theme-plain {
    padding-top: 50px !important;

    .ngdialog-content {
      font-family: 'Open Sans', sans-serif;
      font-size: 13px;

      .text-indent {
        padding-left: 20px;
      }
    }
  }
}

.ngdialog.ngdialog-theme-plain.w-500 .ngdialog-content {
  width: 540px; /* Needed to be 540px for the Select Approvers smart.select.component dialog*/
  max-width: 90%;
}

.ngdialog.ngdialog-theme-plain.w-700 .ngdialog-content {
  width: 700px;
  max-width: 90%;
}

.card .title {
  padding: 0 20px 20px 20px;
}

.k-tabstrip {
  .k-tabstrip-items {
    margin-bottom: 20px;
  }
}
