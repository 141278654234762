@import '../../../../app.scss';
.process-mapv2 {
  .process-mapv2-input-placeholder {
    position: relative;
  }

  .process-mapv2-input-placeholder input:valid + .process-mapv2-placeholder {
    display: none;
  }

  .process-mapv2-placeholder {
    position: absolute;
    pointer-events: none;
    top: 0;
    bottom: 0;
    height: 25px;
    left: 28px;
    margin: auto;
    color: #bdbdbd;
    font-style: italic;
    line-height: 13px;
  }

  .process-mapv2-placeholder span {
    font-weight: 700;
  }

  .process-mapv2-search {
    min-width: 370px !important;
    text-indent: 28px;
    max-width: 1000px !important;
    padding-right: 2rem;
  }

  .process-mapv2-clear {
    margin-left: -20px;
  }

  .process-mapv2-search-container .fa-times {
    color: $color-text-primary;
    font-weight: bold;
    cursor: pointer;
  }

  .process-mapv2-search-container .fa-times:hover {
    color: $blue !important;
  }

  .process-mapv2-search-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-width: 13em;
    margin-top: 3px;
    margin-left: 7rem;
    margin-bottom: 1rem;
    white-space: nowrap;
    overflow: hidden;
  }

  .process-mapv2-search-container .fa-magnifying-glass {
    margin-right: -20px;
    display: inline-block !important;
    height: 32px !important;
    line-height: 20px !important;
    color: grey !important;
    margin-left: 4px;
  }

  .button-group-container-process-maps {
    margin-left: 80px;
    margin-bottom: 10px;
    .button-group-process-maps {
      text-transform: none;
      background: none;
      border: none;
      border-bottom: 1px solid transparent;
      border-radius: 0%;
      color: $color-text-primary;
      font-family: 'Open Sans', sans-serif;
      font-size: 15px !important;
      letter-spacing: 0;
      &.selected {
        border-bottom-color: $color-primary-blue;
        border-width: 2px;
        background-color: #eeeeee;
      }
      &:hover {
        border-color: $color-tab-border-hover;
        border-bottom-width: 2px;
      }
    }
  }
}
