@import '../../app.scss';
.flow-history-start {
  position: relative;
  border: 1px solid $block-border-color;
  border-top: none;
  background-color: $history-background;
  min-height: $history-start-height;
  line-height: $history-block-height;
  padding-left: $block-header-indent; //70px
  padding-top: 5px;

  .avatar {
    top: 5px;
    left: 25px;
  }

  .text {
    margin-left: -10px;
  }
}

.cc-users {
  padding-bottom: 3px;
}
/* MOBILE ///////////////////////////////////////////////////////////// */
.flow-history-start.isMobile {
  padding-top: 5px;

  .text {
    line-height: 16px;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-right: 5px;
    margin-left: -20px;
  }
}
