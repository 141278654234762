.share-modal-container {
  padding: 0 20px 12px;
  margin: 10px 0 2px 20px;
  min-width: 570px;
  .tooltip {
    position: absolute;
    display: inline-block;
  }
  .tooltip .tooltiptext {
    font-size: 13px;
    font-family: sans-serif;
    visibility: visible;

    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 1s;
  }

  .tooltip .tooltiptext::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }

  .tooltiptext--show {
    visibility: visible !important;
    opacity: 1 !important;
  }
}

.share-modal-heading {
  font-size: 20px;
  margin-bottom: 20px;
  font-weight: 600;
}

.share-modal-label {
  font-size: 16px;
  margin-bottom: 8px;
  font-weight: 600;
}

.share-modal-textarea-container {
  position: relative;
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.share-modal-textarea {
  position: relative;
  width: calc(100% - 5px);
  padding: 8px;
  margin-top: 8px;
  font-size: 14px;
  height: auto;
  padding-right: 30px;
  resize: none;
  border: solid 1px #f0f0f0;
  border-radius: 2px;
  font-family: 'Open Sans', sans-serif;
  font-size: 13px !important;
}

.share-modal-icon-container {
  right: 10px;
  cursor: pointer;
  font-size: 18px;
  top: 10px;
  padding-left: 2px;
}

.share-modal-done-button {
  display: flex;
  flex-direction: row-reverse;
}
