@import './app.scss';

.process-map-v2-decision-gateway-label-name {
  font-style: italic;
  margin: 0;
}

.process-map-v2-decision-gateway-italic-and-grayed {
  font-family: 'Open Sans', sans-serif;
  font-style: italic;
  color: $placeholder-color;
}

.process-map-v2-decision-gateway-content-wrapper {
  display: flex;
  align-items: center;
}

.process-map-v2-decision-gateway-content-item {
  flex: 2;
  margin-right: 5px;
}

.process-map-v2-decision-gateway-content-item-2 {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.process-map-v2-decision-gateway-label {
  margin-bottom: 13px;
}

.process-map-v2-decision-gateway {
  margin: 13px 0px;
}
