@import '../app.scss';
.report-group-container {
  //box-shadow: 0 2px 5px 0 rgba(0,0,0,.16),0 2px 10px 0 rgba(0,0,0,.12);
  //start with no top/bottom margins - we will add some and reduce the side margins when item is clicked
  //this will make it expand, but have space between it and adjacent flows

  &.expanded {
    margin: 20px 0px;
    transition: margin 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94);

    .report-grouop-header {
      min-height: $block-height-expanded;
      line-height: $block-height-expanded;
      font-size: $font-size-large;

      h2 {
        padding-left: 60px;
      }
    }
  }

  &.expanded.isMobile {
    .report-header h2 {
      padding-left: 35px;
    }
  }
}
