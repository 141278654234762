/*
    We try to follow BEM as css standard, refer to http://getbem.com/ for more information.
    Legacy code may not follow this standard at all, however we are aiming for new codes.
*/

.page-header-description {
  padding-right: 25px;
  position: fixed;
  background-color: #ebebeb;
  width: 100%;
  z-index: 3 !important;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.page-header-description_wrapper {
  height: 50px;
  margin-bottom: 17px;
}

.page-header-description__top-space {
  margin-top: 43px;
}

.page-header-description__contents {
  padding-top: 7px;
  height: 100%;
}

.page-header-description__text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.page-header-description__help-link {
  white-space: nowrap;
  margin-left: 5px;
}

.page-header-description__actions {
  button {
    margin-top: 0 !important;
    min-width: 10em;
  }
}
