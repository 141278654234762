#flowingly-redirect-block {
  width: 100%;
  height: 100%;
  background: #fff;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  text-align: center;
}
