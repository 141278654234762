//a few helper classes to allow addition of flexbox layout
//See Also:
//https://developer.mozilla.org/en/docs/Web/CSS/flex
//http://caniuse.com/#feat=flexbox
//https://css-tricks.com/snippets/css/a-guide-to-flexbox/
//http://flexboxfroggy.com/
.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-justify-space-between {
  justify-content: space-between;
}

.flex-justify-end {
  justify-content: flex-end;
}

.flex-align-items-end {
  align-items: flex-end;
}
