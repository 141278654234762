.process-map-v2-view-resize-handle-icon [class*='fa-'] {
  background-color: #fff;
  color: #ece9e9;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  margin-left: -25px;
  font-size: 18px;
  text-align: center;
  display: inline-block;
  box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
  position: fixed;
}

.process-map-v2-view-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #ffffff;
}

.process-map-v2-view-resize-handle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10px;
  cursor: e-resize;
  border-top: solid #ddd;
  position: absolute;
  top: -3px;
  left: 0;
  bottom: 0;
}

.process-map-v2-view-resize-handle-is-dragging {
  border-left: 2px solid #428bca;
}

div.process-map-v2-view-drawer-paper {
  top: 50px;
  bottom: 0;
  height: calc(100% - 50px);
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 8px 0 #63636333;
  z-index: 10;
}

.process-map-wrapper {
  position: relative;
}

.process-map-v2-view-error-icon {
  font-size: 90px;
  color: rgba(204, 204, 204, 1);
}
