#setup-features {
  display: flex;
  justify-content: space-between;

  #left-pane {
    max-width: 50%;
  }

  #right-pane {
    max-width: 50%;
    flex-grow: 1;
  }

  #features-select-business-container {
    margin-bottom: 20px;
  }
}
